interface Iprops {
  uniqueId: string
  checked: boolean
  onChange: (e: boolean) => void
  label: string
  idAttr?: string
  disabled?: boolean
}
const CheckboxInput: React.FC<(React.HTMLProps<HTMLLabelElement> & Iprops) | Iprops> = ({
  uniqueId,
  checked,
  onChange,
  label,
  idAttr = label,
  disabled = false,
  ...otherLabelProps
}) => {
  // toConsider
  const unique = `${idAttr}-${uniqueId}`
  return (
    <div className='CheckBoxInput'>
      <input
        disabled={disabled}
        id={unique}
        type='checkbox'
        checked={checked}
        onChange={() => onChange(!checked)}
      />
      <label {...otherLabelProps} htmlFor={unique}>
        {label}
      </label>
    </div>
  )
}

export default CheckboxInput
