import { useState } from 'react'

import { isJsonValid } from '../../../util/tools/tools'

interface Iprops {
  uniqueId: string
  onChange: (e: string) => void
  defaultValue: string
  required?: boolean
  className?: string
}
const ObjectInput: React.FC<Iprops> = ({
  uniqueId,
  defaultValue,
  onChange,
  required = false,
  className = 'defaultObjektInput',
  ...otherInputProps
}) => {
  // toConsider
  const [validationColor, setValidationColor] = useState('green')
  function validateJson(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.currentTarget
    if (isJsonValid(value)) {
      onChange(JSON.parse(value))
      setValidationColor('green')
    } else {
      onChange('{}')
      setValidationColor('red')
    }
  }

  const unique = `ObjectInput-${uniqueId}`

  return (
    <div className='ObjectInput'>
      <h4>Extra inputs</h4>
      <p className='extrasHint'>** Must be in JSON format</p>
      <textarea
        style={{ borderColor: validationColor }}
        id={unique}
        defaultValue={defaultValue}
        onChange={validateJson}
        required={required}
        {...otherInputProps}
        className={className}
      />
    </div>
  )
}

export default ObjectInput
