interface Iprops {
  uniqueId: string
  value?: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  label: string
  required?: boolean
  idAttr?: string
  styleType?: 'default' | 'labelAbove'
}
const TextInput: React.FC<React.HTMLProps<HTMLInputElement> & Iprops> = ({
  uniqueId,
  value,
  onChange,
  label,
  required = true,
  idAttr = label,
  styleType = 'default',
  ...otherInputProps
}) => {
  const unique = `${idAttr}-${uniqueId}`
  // toConsider
  return (
    <div className={`TextInput ${styleType}`}>
      <label htmlFor={unique}>{label}</label>
      <input
        id={unique}
        type='text'
        value={value}
        onChange={onChange}
        required={required}
        {...otherInputProps}
      />
    </div>
  )
}

export default TextInput
