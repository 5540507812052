import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useDuplicateFeedMutation } from '../../../api/api'
import ActivateButt from '../../../components/atoms/buttons/activateButt/ActivateButt'
import EditButt from '../../../components/atoms/buttons/editButt/EditButton'
import { DropDownContainer } from '../../../components/organisms/dropdownContainer/DropDownContainer'
import { Tresponse } from '../../../types/Tgeneral'
import { colorizeImportRunStatus, removeMiliSeconds } from '../../../util/tools/tools'
import { Iprops } from '../types'

const SingleFeed: React.FC<Iprops> = ({ id, name, reader, publisher, importRun, isActive }) => {
  const [displayDropdown, setDisplayDropdown] = useState(false)
  const closeDropdown = () => {
    setDisplayDropdown(false)
  }

  return (
    <div className='SingleFeed row'>
      <ul className={isActive ? 'active' : 'inactive'}>
        <li className='activateButt'>
          <ActivateButt isActive={isActive} />
        </li>
        <li>
          <Link to={`/feeds/${id}`} className='blue'>
            {name}
          </Link>
        </li>
        <li>{reader}</li>
        <li>{publisher}</li>
        <li>
          {!importRun[0]?.status ? (
            <p className='noImportRunLine'>-------------</p>
          ) : (
            <Link to={`/feeds/lastimportrun/${id}/${name}`} className='importRunsClickable'>
              <p style={{ color: colorizeImportRunStatus(importRun[0]?.status) }}>
                {importRun[0]?.status}
              </p>
              <p>{removeMiliSeconds(importRun[0]?.startTime)}</p>
            </Link>
          )}
        </li>
        <li className='editButt'>
          <Link to={`/editfeed/${Number(id)}`}>
            <EditButt />
          </Link>
        </li>
        <li className='advancedButt'>
          <div></div>
          <DropDownContainer
            closeDropdown={closeDropdown}
            setDisplayDropdown={setDisplayDropdown}
            displayDropdown={displayDropdown}
          >
            <DropdownOptions id={id} name={name} />
          </DropDownContainer>
        </li>
      </ul>
    </div>
  )
}

export default SingleFeed

interface Idropdown {
  id: number | undefined
  name: string
}

const DropdownOptions: React.FC<Idropdown> = ({ id, name }) => {
  const [duplicateFeed] = useDuplicateFeedMutation()

  async function duplicate() {
    const response: Tresponse = await duplicateFeed(Number(id))
    const feedId = response?.data?.message.id
    if (id) {
      const location = `/#/editfeed/${feedId}`

      setTimeout(() => {
        window.location.replace(location)
      }, 500)
    }
  }

  return (
    <ul>
      <Link to={`/feeds/importruns/${id}/${name}`}>
        <li>Imports</li>
      </Link>
      <Link to={`/feeds/jobpostings/${id}/${name}`}>
        <li>Job postings</li>
      </Link>
      {localStorage.userRole !== 'view' && (
        <Link to={`/deletefeed/${id}`}>
          <li className='delete'>Delete</li>
        </Link>
      )}
      {id && (
        <li onClick={() => duplicate()} className='duplicateFeed'>
          Duplicate Feed
        </li>
      )}
    </ul>
  )
}
