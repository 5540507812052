export default {
  jobsContext: 'jobs/job',
  id: 'id',
  title: 'title',
  publishDate: 'publish_date',
  company: 'company',
  embeddedHtml: 'description',
  showUrl: 'direct_url',
  applyUrl: 'apply_url',
  applyEmail: 'apply_email',
  referenceNumber: 'reference_no',
  companyUrl: 'company_url',
  expirationDate: 'expiration_date',
  employmentType: 'employment_type',
  employmentStartDate: 'employment_start_date',
  remoteWork: 'remote_work',
  headcount: 'headcount',
  language: 'language',
  keywords: 'keywords',

  industry: { id: '', title: 'industry' },
  seniority: { id: '', title: '' },
  fieldOfWork: { id: '', title: 'function' },
  contractType: {
    id: '',
    title: 'contract_type',
  },
  positionType: {
    id: '',
    title: 'employment_type',
  },

  contact: {
    name: 'concat(contact_firstname, " ", contact_lastname)',
    url: '',
    email: 'contact_email',
    phone: 'contact_phone',
  },

  salary: {
    minimum: 'salary/min',
    maximum: 'salary/max',
    currency: 'salary/currency',
    interval: 'salary/interval',
  },

  locationContext: 'locations/location',
  locations: {
    zip: 'zip',
    city: 'city',
    countryCode: 'country_code',
    country: 'country',
    street: 'street',
    streetNumber: 'street_number',
    region: 'region',
  },

  content: {
    hiringOrganizationTitle: 'content/introduction/@title',
    hiringOrganization: 'content/introduction',
    responsibilitiesTitle: 'content/responsibilities/@title',
    responsibilities: 'content/responsibilities',
    qualificationsTitle: 'content/qualifications/@title',
    qualifications: 'content/qualifications',
    incentivesTitle: 'content/incentives/@title',
    incentives: 'content/incentives',
    contactTitle: 'content/contact/@title',
    contact: 'content/contact',
  },

  branding: {
    primaryColor: '',
    secondaryColor: '',
    headerImage: 'header_image',
    sliderImages: '',
    backgroundImage: '',
    video: 'video_url',
    logo: 'logo_url',
  },
}
