import { Link, useParams } from 'react-router-dom'

import { useSingleFeedLastImportRunQuery } from '../../../../api/api'
import LoadingSpinner from '../../../../components/atoms/loadingSpinner/LoadingSpinner'
import { SingleRun } from '../importRuns/components/SingleRun'

const LastImportRun: React.FC = () => {
  const { feedId, feedName } = useParams()

  const { data, isError, isLoading, error } = useSingleFeedLastImportRunQuery(feedId)
  isLoading && <LoadingSpinner />
  if (isError) {
    return <h1>{JSON.stringify(error, null, 2)}</h1>
  }

  if (!data || !Object.values(data)) {
    return <h1 className='emptyList'>No import run for this feed</h1>
  }

  return (
    <div className='LastImportRun pageContainer'>
      <div className='breadcrumbs'>
        <span>
          <Link to='/feeds'>
            <p>feeds</p>
          </Link>
        </span>
        <span> / </span>
        <span>
          {feedName} : {feedId}
        </span>
      </div>
      <h1>
        Last import run for <span>{feedName}</span>
      </h1>
      <div className='table'>
        <div className='tableHeader'>
          <ul>
            <li>ImportId</li>
            <li>Status</li>
            <li>Start time</li>
            <li>End time</li>
            <li>Result</li>
          </ul>
        </div>
        {data?.id && (
          <SingleRun
            id={data.id}
            status={data.status}
            startTime={data.startTime}
            endTime={data.endTime}
            result={data.result}
          />
        )}
      </div>
    </div>
  )
}

export default LastImportRun
