import { ThtmlSource } from '../../../../types/Tfeeds'
import { TreaderConfigGenericJson, TreaderConfigGenericXml } from '../../../../types/readerConfig'
import GenericJson from './GenericJson'
import GenericXml from './GenericXml'

interface Iprops {
  initialValues: TreaderConfigGenericXml | TreaderConfigGenericJson
  reader: string
  htmlSource: ThtmlSource
}
const ReaderConfig: React.FC<Iprops> = ({ initialValues, reader, htmlSource }) => {
  return (
    <div className='ReaderConfig'>
      {reader === 'generic-json' && (
        <GenericJson initialValues={initialValues} htmlSource={htmlSource} />
      )}
      {reader === 'generic-xml' && (
        <GenericXml initialValues={initialValues} htmlSource={htmlSource} />
      )}
      {reader === 'onlyjobs' && (
        <GenericXml initialValues={initialValues} htmlSource={htmlSource} />
      )}
    </div>
  )
}

export default ReaderConfig
