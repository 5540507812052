import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { TpublisherConfig } from '../../../../../types/Tfeeds'
import { setOtherConfig } from '../../reduxStates'

const useAllHooks = (
  publisherConfig: TpublisherConfig,
  defaultPublisher: string,
  publisher: string,
) => {
  const dispatch = useDispatch()
  const uniqueId = '87878'

  const [configs, setConfigs] = useState<string>('')
  const [extrasDefault, setExtrasDefault] = useState<string>('')

  useEffect(() => {
    setExtrasDefault(JSON.stringify(publisherConfig))
  }, [publisherConfig])

  useEffect(() => {
    if (defaultPublisher !== 'jass' && defaultPublisher !== 'schalttool') {
      setConfigs(JSON.parse(JSON.stringify(publisherConfig, null, 2)))
    } else {
      setConfigs('{}')
    }
  }, [defaultPublisher, publisherConfig])

  useEffect(() => {
    if (Object.values(configs).length && publisher !== 'jass' && publisher !== 'schalttool') {
      const newPublisherConfig = configs
      dispatch(setOtherConfig(newPublisherConfig))
    }
  }, [dispatch, configs, publisher])

  return { uniqueId, configs, setConfigs, extrasDefault }
}

export default useAllHooks
