import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HashRouter, Route, Routes } from 'react-router-dom'

import { useValidateTokenMutation } from './api/api'
import BackButton from './components/atoms/buttons/backButton/BackButton'
import LoadingSpinner from './components/atoms/loadingSpinner/LoadingSpinner'
import Header from './components/templates/header/Header'
import Login from './components/templates/login/Login'
import Panel from './pages/dashboard/Dashboard'
import AddNewFeed from './pages/feedCRUD/addNewFeed/AddNewFeed'
import DeleteFeed from './pages/feedCRUD/deleteFeed/DeleteFeed'
import EditFeed from './pages/feedCRUD/editFeed/EditFeed'
import MappingHelperJson from './pages/feedCRUD/sharedComponents/MappingHelpers/MappingHelperJson'
import MappingHelperXml from './pages/feedCRUD/sharedComponents/MappingHelpers/MappingHelperXml'
import { FeedPage } from './pages/feedPage/FeedPage'
import FeedsList from './pages/feedsList/FeedsList'
import ImportRuns from './pages/feedsList/singleFeed/importRuns/ImportRuns'
import JobPostingImports from './pages/feedsList/singleFeed/jobPostingImports/JobPostingImports'
import JobPostings from './pages/feedsList/singleFeed/jobPostings/JobPostings'
import { JobPostingDetails } from './pages/feedsList/singleFeed/jobPostings/jobPostingDetails/JobPostingDetails'
import LastImportRun from './pages/feedsList/singleFeed/lastImportRun/LastImportRun'
import Templates from './pages/templates/Templates'
import AddNewTemplate from './pages/templates/addNewTemplate/AddNewTemplate'
import DeleteTemplate from './pages/templates/deleteTemplate/DeleteTemplate'
import HtmlContent from './pages/templates/htmlContent/HtmlContent'
import { EditUser } from './pages/users/EditUser'
import { Users } from './pages/users/Users'
import { setToken } from './redux/slices/auth'
import { setIsUserLoggedIn } from './redux/slices/user'
import { RootState } from './redux/store'
import { Tresponse } from './types/Tgeneral'

const PathRoutes: React.FC = () => {
  const { user, auth } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()

  const [validateToken, { isLoading, isError }] = useValidateTokenMutation()

  useEffect(() => {
    dispatch(setToken(localStorage.token))

    async function checkCredentials() {
      const response: Tresponse = await validateToken()
      if (response.data?.result === 'token valid') {
        dispatch(setIsUserLoggedIn(true))
      } else {
        dispatch(setIsUserLoggedIn(false))
      }
    }
    checkCredentials()
  }, [validateToken, dispatch, auth])

  isError && <h1>Token Validation failed</h1>

  if (!isLoading) {
    return (
      <div className='PathRoutes'>
        {!user.isUserLoggedIn ? (
          <Login />
        ) : (
          <HashRouter>
            <Header />
            <Routes>
              <Route path='/' element={<FeedsList />} />
              <Route path='/feeds' element={<FeedsList />} />
              <Route path='/feeds/:id' element={<FeedPage />} />
              <Route path='/templates' element={<Templates />} />
              <Route path='/templates/delete/:templateName' element={<DeleteTemplate />} />
              <Route path='/addNewTemplate' element={<AddNewTemplate />} />
              <Route path='/templates/htmlContent/:name' element={<HtmlContent />} />
              <Route path='/panel' element={<Panel />} />
              <Route path='/users' element={<Users />} />
              <Route path='/users/:id/:username/:role' element={<EditUser />} />
              <Route path='/editfeed/:feedId' element={<EditFeed />} />
              <Route path='/addnewfeed' element={<AddNewFeed />} />
              <Route path='/deletefeed/:feedId' element={<DeleteFeed />} />
              <Route path='/feeds/importruns/:feedId/:feedName' element={<ImportRuns />} />
              <Route path='/feeds/lastimportrun/:feedId/:feedName' element={<LastImportRun />} />
              <Route path='/feeds/jobpostings/:feedId/:feedName' element={<JobPostings />} />
              <Route path='/feeds/jobpostings/:jobPostingId' element={<JobPostingDetails />} />
              <Route path='/feeds/jobpostingimports/:importRunId' element={<JobPostingImports />} />
              <Route path='/mappinghelperjson' element={<MappingHelperJson />} />
              <Route path='/mappinghelperxml' element={<MappingHelperXml />} />
              <Route path='*' element={<h1>404 Not Found</h1>} />
            </Routes>
            <BackButton />
          </HashRouter>
        )}
      </div>
    )
  } else {
    return <LoadingSpinner />
  }
}
export default PathRoutes
