import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'

import PathRoutes from './PathRoutes'
import { store } from './redux/store'
import './styles/index.scss'

function App() {
  return (
    <Provider store={store}>
      <div className='App'>
        <PathRoutes />
      </div>
    </Provider>
  )
}

export default App
