import { DividerLine } from '../../../../components/atoms/lines/DividerLine'
import TextInput from '../../../../components/organisms/textInput/TextInput'
import { ThtmlSource } from '../../../../types/Tfeeds'
import { TmappingConfigs } from '../../../../types/Tmappings'
import useAllHooks from './hooksAndStates'
import './style.scss'

interface Iprops {
  htmlSource: ThtmlSource
  setConfigs: (configs: TmappingConfigs) => void
  initialValues?: TmappingConfigs
}

export const MappingFields: React.FC<Iprops> = ({ htmlSource, setConfigs, initialValues }) => {
  const {
    id,
    setId,
    title,
    setTitle,
    publishDate,
    setPublishDate,
    showUrl,
    setShowUrl,
    embeddedHtml,
    setEmbeddedHtml,
    referenceNumber,
    setReferenceNumber,
    zipUrl,
    setZipUrl,
    company,
    setCompany,
    applyUrl,
    setApplyUrl,
    applyEmail,
    setApplyEmail,
    language,
    setLanguage,
    headcount,
    setHeadcount,
    industryId,
    setIndustryId,
    fieldOfWorkId,
    setFieldOfWorkId,
    contractTypeId,
    setContractTypeId,
    seniorityId,
    setSeniorityId,
    positionTypeId,
    setPositionTypeId,
    industryTitle,
    setIndustryTitle,
    fieldOfWorkTitle,
    setFieldOfWorkTitle,
    contractTypeTitle,
    seniorityTitle,
    setSeniorityTitle,
    positionTypeTitle,
    setPositionTypeTitle,
    employmentStartDate,
    setEmploymentStartDate,
    keywords,
    setKeywords,
    locationContext,
    setLocationContext,
    locationCity,
    setLocationCity,
    locationZip,
    setLocationZip,
    locationStreet,
    setLocationStreet,
    locationStreetNumber,
    setLocationStreetNumber,
    locationRegion,
    setLocationRegion,
    locationCountry,
    setLocationCountry,
    locationCountryCode,
    setLocationCountryCode,
    salaryMinimum,
    setSalaryMinimum,
    salaryMaximum,
    logo,
    setLogo,
    setSalaryMaximum,
    sliderImages,
    setSliderImages,
    salaryCurrency,
    video,
    setVideo,
    setSalaryCurrency,
    backgroundImage,
    setBackgroundImage,
    salaryInterval,
    headerImage,
    setHeaderImage,
    setSalaryInterval,
    secondaryColor,
    setSecondaryColor,
    contactName,
    primaryColor,
    setPrimaryColor,
    uniqueId,
    setContactName,
    contentContact,
    setContentContact,
    contactUrl,
    contentContactTitle,
    setContentContactTitle,
    setContractTypeTitle,
    setContactUrl,
    incentives,
    setIncentives,
    contactEmail,
    incentivesTitle,
    setIncentivesTitle,
    setContactEmail,
    qualifications,
    setQualifications,
    contactPhone,
    qualificationsTitle,
    setQualificationsTitle,
    setContactPhone,
    responsibilities,
    setResponsibilities,
    hiringOrganizationTitle,
    responsibilitiesTitle,
    setResponsibilitiesTitle,
    setHiringOrganizationTitle,
    hiringOrganization,
    setHiringOrganization,
  } = useAllHooks(setConfigs, initialValues)

  return (
    <div className='MappingFields'>
      <div className='inputs'>
        <div className='singles'>
          <h3>Root fields</h3>
          <div>
            <TextInput
              label='ID'
              uniqueId={uniqueId}
              onChange={(e) => setId(e.currentTarget.value)}
              value={id}
              placeholder='ex: id'
            />
            <TextInput
              label='Title'
              uniqueId={uniqueId}
              onChange={(e) => setTitle(e.currentTarget.value)}
              value={title}
            />
            <TextInput
              label='Publish date'
              uniqueId={uniqueId}
              onChange={(e) => setPublishDate(e.currentTarget.value)}
              value={publishDate}
              required={false}
            />
            <TextInput
              label='Show url'
              uniqueId={uniqueId}
              onChange={(e) => setShowUrl(e.currentTarget.value)}
              value={showUrl}
            />
            <TextInput
              label='Embedded Html'
              uniqueId={uniqueId}
              onChange={(e) => setEmbeddedHtml(e.currentTarget.value)}
              value={embeddedHtml}
              required={htmlSource === 'embedded'}
            />
            <TextInput
              label='Reference Number'
              uniqueId={uniqueId}
              onChange={(e) => setReferenceNumber(e.currentTarget.value)}
              value={referenceNumber}
              required={false}
            />
            <TextInput
              label='Zip url'
              uniqueId={uniqueId}
              onChange={(e) => setZipUrl(e.currentTarget.value)}
              value={zipUrl}
              required={false}
            />
            <TextInput
              label='Company'
              uniqueId={uniqueId}
              onChange={(e) => setCompany(e.currentTarget.value)}
              value={company}
              required={false}
            />
            <TextInput
              label='Apply url'
              uniqueId={uniqueId}
              onChange={(e) => setApplyUrl(e.currentTarget.value)}
              value={applyUrl}
              required={false}
            />
            <TextInput
              label='Apply E-mail'
              uniqueId={uniqueId}
              onChange={(e) => setApplyEmail(e.currentTarget.value)}
              value={applyEmail}
              required={false}
            />
            <TextInput
              label='Language'
              uniqueId={uniqueId}
              onChange={(e) => setLanguage(e.currentTarget.value)}
              value={language}
              required={false}
            />
            <TextInput
              label='Headcount'
              uniqueId={uniqueId}
              onChange={(e) => setHeadcount(e.currentTarget.value)}
              value={headcount}
              required={false}
            />
            <TextInput
              label='Industry (id)'
              uniqueId={uniqueId}
              onChange={(e) => setIndustryId(e.currentTarget.value)}
              value={industryId}
              required={false}
            />
            <TextInput
              label='Industry (title)'
              uniqueId={uniqueId}
              onChange={(e) => setIndustryTitle(e.currentTarget.value)}
              value={industryTitle}
              required={false}
            />
            <TextInput
              label='Field of work  (id)'
              uniqueId={uniqueId}
              onChange={(e) => setFieldOfWorkId(e.currentTarget.value)}
              value={fieldOfWorkId}
              required={false}
            />
            <TextInput
              label='Field of work  (title)'
              uniqueId={uniqueId}
              onChange={(e) => setFieldOfWorkTitle(e.currentTarget.value)}
              value={fieldOfWorkTitle}
              required={false}
            />
            <TextInput
              label='Contract type (id)'
              uniqueId={uniqueId}
              onChange={(e) => setContractTypeId(e.currentTarget.value)}
              value={contractTypeId}
              required={false}
            />
            <TextInput
              label='Contract type (title)'
              uniqueId={uniqueId}
              onChange={(e) => setContractTypeTitle(e.currentTarget.value)}
              value={contractTypeTitle}
              required={false}
            />
            <TextInput
              label='Position type (id)'
              uniqueId={uniqueId}
              onChange={(e) => setPositionTypeId(e.currentTarget.value)}
              value={positionTypeId}
              required={false}
            />
            <TextInput
              label='Position type (title)'
              uniqueId={uniqueId}
              onChange={(e) => setPositionTypeTitle(e.currentTarget.value)}
              value={positionTypeTitle}
              required={false}
            />
            <TextInput
              label='Seniority (id)'
              uniqueId={uniqueId}
              onChange={(e) => setSeniorityId(e.currentTarget.value)}
              value={seniorityId}
              required={false}
            />
            <TextInput
              label='Seniority (title)'
              uniqueId={uniqueId}
              onChange={(e) => setSeniorityTitle(e.currentTarget.value)}
              value={seniorityTitle}
              required={false}
            />
            <TextInput
              label='Employment start date'
              uniqueId={uniqueId}
              onChange={(e) => setEmploymentStartDate(e.currentTarget.value)}
              value={employmentStartDate}
              required={false}
            />
            <TextInput
              label='Keywords'
              uniqueId={uniqueId}
              onChange={(e) => setKeywords(e.currentTarget.value)}
              value={keywords}
              required={false}
            />
          </div>
        </div>
        <div className='location'>
          <h3>Location</h3>
          <TextInput
            label='Location context'
            uniqueId={uniqueId}
            onChange={(e) => setLocationContext(e.currentTarget.value)}
            value={locationContext}
            required={false}
            placeholder='ex: locations'
          />
          <DividerLine />
          <TextInput
            label='City'
            uniqueId={uniqueId}
            onChange={(e) => setLocationCity(e.currentTarget.value)}
            value={locationCity}
            required={false}
            placeholder='ex: location.city'
          />
          <TextInput
            label='Zip'
            uniqueId={uniqueId}
            onChange={(e) => setLocationZip(e.currentTarget.value)}
            value={locationZip}
            required={false}
          />
          <TextInput
            label='Street'
            uniqueId={uniqueId}
            onChange={(e) => setLocationStreet(e.currentTarget.value)}
            value={locationStreet}
            required={false}
          />
          <TextInput
            label='Street Number'
            uniqueId={uniqueId}
            onChange={(e) => setLocationStreetNumber(e.currentTarget.value)}
            value={locationStreetNumber}
            required={false}
          />
          <TextInput
            label='Region'
            uniqueId={uniqueId}
            onChange={(e) => setLocationRegion(e.currentTarget.value)}
            value={locationRegion}
            required={false}
          />
          <TextInput
            label='Country'
            uniqueId={uniqueId}
            onChange={(e) => setLocationCountry(e.currentTarget.value)}
            value={locationCountry}
            required={false}
          />
          <TextInput
            label='Country code'
            uniqueId={uniqueId}
            onChange={(e) => setLocationCountryCode(e.currentTarget.value)}
            value={locationCountryCode}
            required={false}
          />
        </div>
        <div className='salary'>
          <h3>Salary</h3>
          <TextInput
            label='Minimum'
            uniqueId={uniqueId}
            onChange={(e) => setSalaryMinimum(e.currentTarget.value)}
            value={salaryMinimum}
            required={false}
            placeholder='ex: salary.minimum'
          />
          <TextInput
            label='Maximum'
            uniqueId={uniqueId}
            onChange={(e) => setSalaryMaximum(e.currentTarget.value)}
            value={salaryMaximum}
            required={false}
          />
          <TextInput
            label='Currency'
            uniqueId={uniqueId}
            onChange={(e) => setSalaryCurrency(e.currentTarget.value)}
            value={salaryCurrency}
            required={false}
          />
          <TextInput
            label='Interval'
            uniqueId={uniqueId}
            onChange={(e) => setSalaryInterval(e.currentTarget.value)}
            value={salaryInterval}
            required={false}
          />
        </div>
        <div className='contact'>
          <h3>Contact</h3>
          <TextInput
            label='Name'
            uniqueId={uniqueId}
            onChange={(e) => setContactName(e.currentTarget.value)}
            value={contactName}
            required={false}
            placeholder='ex: contact.name'
          />
          <TextInput
            label='Url'
            uniqueId={uniqueId}
            onChange={(e) => setContactUrl(e.currentTarget.value)}
            value={contactUrl}
            required={false}
          />
          <TextInput
            label='Email'
            uniqueId={uniqueId}
            onChange={(e) => setContactEmail(e.currentTarget.value)}
            value={contactEmail}
            required={false}
          />
          <TextInput
            label='Phone'
            uniqueId={uniqueId}
            onChange={(e) => setContactPhone(e.currentTarget.value)}
            value={contactPhone}
            required={false}
          />
        </div>
        <div className='content'>
          <h3>Content</h3>
          <TextInput
            label='Hiring organization Title'
            uniqueId={uniqueId}
            onChange={(e) => setHiringOrganizationTitle(e.currentTarget.value)}
            value={hiringOrganizationTitle}
            required={false}
          />
          <TextInput
            label='Hiring organization'
            uniqueId={uniqueId}
            onChange={(e) => setHiringOrganization(e.currentTarget.value)}
            value={hiringOrganization}
            required={false}
            placeholder='ex: content.hiringOrganization'
          />
          <TextInput
            label='Responsibilities Title'
            uniqueId={uniqueId}
            onChange={(e) => setResponsibilitiesTitle(e.currentTarget.value)}
            value={responsibilitiesTitle}
            required={false}
          />
          <TextInput
            label='Responsibilities'
            uniqueId={uniqueId}
            onChange={(e) => setResponsibilities(e.currentTarget.value)}
            value={responsibilities}
            required={false}
          />
          <TextInput
            label='Qualifications Title'
            uniqueId={uniqueId}
            onChange={(e) => setQualificationsTitle(e.currentTarget.value)}
            value={qualificationsTitle}
            required={false}
          />
          <TextInput
            label='Qualifications'
            uniqueId={uniqueId}
            onChange={(e) => setQualifications(e.currentTarget.value)}
            value={qualifications}
            required={false}
          />
          <TextInput
            label='Incentives Title'
            uniqueId={uniqueId}
            onChange={(e) => setIncentivesTitle(e.currentTarget.value)}
            value={incentivesTitle}
            required={false}
          />
          <TextInput
            label='Incentives'
            uniqueId={uniqueId}
            onChange={(e) => setIncentives(e.currentTarget.value)}
            value={incentives}
            required={false}
          />
          <TextInput
            label='Content-Contact Title'
            uniqueId={uniqueId}
            onChange={(e) => setContentContactTitle(e.currentTarget.value)}
            value={contentContactTitle}
            required={false}
          />
          <TextInput
            label='Content-Contact'
            uniqueId={uniqueId}
            onChange={(e) => setContentContact(e.currentTarget.value)}
            value={contentContact}
            required={false}
          />
        </div>
        <div className='branding'>
          <h3>Branding</h3>
          <TextInput
            label='Primary color'
            uniqueId={uniqueId}
            onChange={(e) => setPrimaryColor(e.currentTarget.value)}
            value={primaryColor}
            required={false}
            placeholder='ex: branding.primaryColor'
          />
          <TextInput
            label='Secondary color'
            uniqueId={uniqueId}
            onChange={(e) => setSecondaryColor(e.currentTarget.value)}
            value={secondaryColor}
            required={false}
          />
          <TextInput
            label='Header image'
            uniqueId={uniqueId}
            onChange={(e) => setHeaderImage(e.currentTarget.value)}
            value={headerImage}
            required={false}
          />
          <TextInput
            label='Background image'
            uniqueId={uniqueId}
            onChange={(e) => setBackgroundImage(e.currentTarget.value)}
            value={backgroundImage}
            required={false}
          />
          <TextInput
            label='Video'
            uniqueId={uniqueId}
            onChange={(e) => setVideo(e.currentTarget.value)}
            value={video}
            required={false}
          />
          <TextInput
            label='Slider images'
            uniqueId={uniqueId}
            onChange={(e) => setSliderImages(e.currentTarget.value)}
            value={sliderImages}
            required={false}
          />
          <TextInput
            label='Logo'
            uniqueId={uniqueId}
            onChange={(e) => setLogo(e.currentTarget.value)}
            value={logo}
            required={false}
          />
        </div>
      </div>
    </div>
  )
}
