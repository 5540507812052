import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type TuserRole = 'manager' | 'admin' | 'view' | null

interface IuserSlice {
  userId: number | null
  username: string | null
  isUserLoggedIn: boolean
  userRole: TuserRole
}

const initialState = {
  userId: null,
  username: null,
  isUserLoggedIn: false,
  userRole: null,
}
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId(state: IuserSlice, action: PayloadAction<number>) {
      state.userId = action.payload
    },
    setUserName(state: IuserSlice, action: PayloadAction<string>) {
      state.username = action.payload
    },
    setIsUserLoggedIn(state: IuserSlice, action: PayloadAction<boolean>) {
      state.isUserLoggedIn = action.payload
    },
    setUserRole(state: IuserSlice, action: PayloadAction<TuserRole>) {
      state.userRole = action.payload
    },
    setUserEmpty() {
      return initialState
    },
  },
})

export default userSlice.reducer
export const { setUserId, setUserName, setIsUserLoggedIn, setUserRole, setUserEmpty } =
  userSlice.actions
