import { DividerLine } from '../../../../components/atoms/lines/DividerLine'
import SelectBox from '../../../../components/organisms/selectBox/SelectBox'
import TextInput from '../../../../components/organisms/textInput/TextInput'
import { Tauthorization, TauthorizationConfigs } from '../../../../types/Tfeeds'
import useAllHooks from './hooksAndStates'
import './style.scss'

const authMethodList = ['none', 'basic']

interface Iprops {
  authorization?: Tauthorization
  setAuthorization: (e: Tauthorization) => void
  authorizationConfigs: TauthorizationConfigs
  initialValues:
    | undefined
    | { authorization: Tauthorization; authorizationConfigs: TauthorizationConfigs }
}

export const FeedAuth: React.FC<Iprops> = ({ authorization, setAuthorization, initialValues }) => {
  const { uniqueId, setUserName, setPassword } = useAllHooks(
    authorization,
    setAuthorization,
    initialValues,
  )

  return (
    <div className='FeedAuth'>
      <DividerLine />
      <div className=''>
        <SelectBox
          label='Authorization'
          onChange={setAuthorization}
          selectList={authMethodList}
          uniqueId={uniqueId}
          defaultValue={initialValues?.authorization}
        />
      </div>
      {authorization === 'basic' && (
        <>
          <TextInput
            label='Username'
            onChange={(e) => setUserName(e.currentTarget.value)}
            uniqueId={uniqueId}
            defaultValue={initialValues?.authorizationConfigs.username}
          />
          <TextInput
            label='Password'
            onChange={(e) => setPassword(e.currentTarget.value)}
            uniqueId={uniqueId}
            type='password'
            defaultValue={initialValues?.authorizationConfigs.password}
          />
        </>
      )}
    </div>
  )
}
