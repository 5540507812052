import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { DividerLine } from '../../atoms/lines/DividerLine'
import CheckboxInput from '../checkboxInput/CheckboxInput'
import './style.scss'

interface Iprops {
  uniqueId: string
  inputValue: string
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  label: string
  setState: (e: (prevState: string[]) => string[]) => void
  defaultValue: string
  schedule: string[]
  setSchedule: (e: string[]) => void
  required?: boolean
  idAttr?: string
}
const Schedules: React.FC<React.HTMLProps<HTMLInputElement> & Iprops> = ({
  uniqueId,
  inputValue,
  onChange,
  label,
  setState,
  defaultValue,
  schedule,
  setSchedule,
  required = false,
  idAttr = label,
  ...otherInputProps
}) => {
  const unique = `${idAttr}-${uniqueId}`

  const [isCronCustom, setIsCronCustom] = useState(false)

  return (
    <div className='Schedules'>
      <DividerLine />
      <div className='customCron'>
        <CheckboxInput
          label='Custom cron value'
          uniqueId={uniqueId}
          idAttr='customCron'
          checked={isCronCustom}
          onChange={setIsCronCustom}
          style={{ fontSize: 'small' }}
        />
        <a target='_blank' href='https://cron.help/' rel='noreferrer'>
          cron helper
        </a>
      </div>

      <div className='cronInput'>
        <label htmlFor={unique}>{label}</label>
        <div className='inputButt'>
          {isCronCustom ? (
            <input
              id={unique}
              type='text'
              value={inputValue}
              onChange={onChange}
              required={required}
              {...otherInputProps}
            />
          ) : (
            <select
              className='customCronInput'
              defaultValue={defaultValue}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(e)}
              id={unique}
              required={required}
            >
              {process.env.REACT_APP_NODE_ENV === 'development' && (
                <>
                  <option value='*/5 * * * * *'>*/5 * * * * * - At every 5th second</option>
                  <option value='*/10 * * * * *'>*/10 * * * * * - At every 10th second</option>
                </>
              )}
              <option value='*/10 * * * *'>*/10 * * * * - every 10th minute</option>
              <option value='*/30 * * * *'>*/30 * * * * - every 30th minute</option>
              <option value='0 * * * *'>0 * * * * - every hour</option>
              <option value='0 */2 * * *'>0 */2 * * * - every 2nd hour</option>
              <option value='0 */4 * * *'>0 */4 * * * - every 4nd hour</option>
              <option value='0 */12 * * *'>0 */12 * * * - every 12nd hour</option>
              <option value='0 0 * * *'>0 0 * * * - every day at 00:00</option>
            </select>
          )}
          <span
            onClick={() => setState((state: string[]) => [...state, inputValue])}
            className='gg-add'
          />
        </div>
      </div>

      <div className='items'>
        <div className='container'>
          {schedule.map((i) => (
            <div className='item' key={uuidv4()}>
              <span>{i}</span>
              <span onClick={() => removeFromSchedule(i)} className='gg-remove' />
            </div>
          ))}
        </div>
      </div>
      <DividerLine />
    </div>
  )

  function removeFromSchedule(i: string) {
    const arr = schedule.filter((item) => {
      return item !== i
    })
    setSchedule(arr)
  }
}

export default Schedules
