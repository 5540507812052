import { useGetTemplateListQuery } from '../../../../api/api'

interface Iprops {
  onChange: (e: string) => void
  defaultValue?: string
}

const TemplateSelectBox: React.FC<Iprops> = ({ onChange, defaultValue }) => {
  const { data: templates } = useGetTemplateListQuery(null)

  const unique = 'TemplateSelectBox-51984'

  return (
    <div className={'SelectBox TemplateSelectBox'}>
      <label htmlFor={unique}>Template</label>
      <select
        value={defaultValue || templates?.[0]}
        onChange={(e) => onChange(e.target.value)}
        id={unique}
        required={true}
      >
        {templates?.map((i: string) => (
          <option value={i} key={i}>
            {i}
          </option>
        ))}
      </select>
    </div>
  )
}

export default TemplateSelectBox
