import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  TcustomerId,
  TjassConfig,
  Tparser,
  TproductIds,
  TpublisherConfig,
} from '../../../../../types/Tfeeds'
import { setJassConfig } from '../../reduxStates'

const useAllHooks = (
  defaultPublisher: string,
  publisher: string,
  publisherConfig: TpublisherConfig,
) => {
  const dispatch = useDispatch()
  const uniqueId = '85552'
  const [customerId, setCustomerId] = useState<TcustomerId>(0)
  const [productIds, setProductIds] = useState<TproductIds>([0])
  const [parser, setParser] = useState<Tparser>('xml')
  const [productIdsPath, setProductIdsPath] = useState('')
  const [customerIdPath, setCustomerIdPath] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (defaultPublisher === 'jass') {
      setCustomerId(publisherConfig.customerId)
      setProductIds(publisherConfig.productIds)
      setParser(publisherConfig.parser)
      setProductIdsPath(publisherConfig.productIdsPath)
      setCustomerIdPath(publisherConfig.customerIdPath)
      setApiKey(publisherConfig.apiKey)
      setUsername(publisherConfig.username)
      setPassword(publisherConfig.password)
    }
  }, [defaultPublisher, publisherConfig])

  useEffect(() => {
    if (publisher === 'jass') {
      const newJassConfig: TjassConfig = {
        customerId,
        productIds,
        parser,
        productIdsPath,
        customerIdPath,
        apiKey,
        username,
        password,
      }
      dispatch(setJassConfig(newJassConfig))
    }
  }, [
    dispatch,
    customerId,
    productIds,
    publisher,
    parser,
    productIdsPath,
    customerIdPath,
    apiKey,
    username,
    password,
  ])
  return {
    uniqueId,
    customerId,
    setCustomerId,
    productIds,
    setProductIds,
    parser,
    setParser,
    productIdsPath,
    setProductIdsPath,
    customerIdPath,
    setCustomerIdPath,
    apiKey,
    setApiKey,
    username,
    setUsername,
    password,
    setPassword,
  }
}

export default useAllHooks
