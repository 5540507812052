interface Iprops {
  uniqueId: string
  value: number | string
  onChange(e: React.FormEvent<HTMLInputElement>): void
  label: string
  required?: boolean
  idAttr?: string
  styleType?: 'default' | 'labelAbove'
}

const NumberInput: React.FC<React.HTMLProps<HTMLInputElement> & Iprops> = ({
  uniqueId,
  value,
  onChange,
  label,
  required = true,
  idAttr = label,
  styleType = 'default',
  ...otherInputProps
}) => {
  // toConsider
  const unique = `${idAttr}-${uniqueId}`
  return (
    <div className={`NumberInput ${styleType}`}>
      <label htmlFor={unique}>{label}</label>
      <input
        id={unique}
        type='number'
        value={value}
        onChange={(e) => onChange(e)}
        required={required}
        min={0}
        {...otherInputProps}
      />
    </div>
  )
}

export default NumberInput
