/* eslint-disable camelcase */
import { useEffect, useState } from 'react'

import CheckboxInput from '../../../../../components/organisms/checkboxInput/CheckboxInput'
import { TfreeBundleJobBoards } from '../../../../../types/Tpublishers'

const uniqueId = '62531'

interface Props {
  setFreeBundleJobBoards: (value: TfreeBundleJobBoards) => void
  initialValues?: any
}

const JobBoards: React.FC<Props> = ({ setFreeBundleJobBoards, initialValues }) => {
  const [adzuna, setAdzuna] = useState(true)
  const [jobNinja, setJobNinja] = useState(true)
  const [kazini, setKazini] = useState(true)
  const [meega, setMeega] = useState(true)
  const [aktuelle_jobs_de, setAktuelle_jobs_de] = useState(true)
  const [jobrapido, setJobrapido] = useState(true)
  const [kimeta_free, setKimeta_Free] = useState(true)
  const [njobs, setNjobs] = useState(true)
  const [herojob, setHerojob] = useState(true)
  const [jobroller_free, setJobroller_free] = useState(true)
  const [local24, setLocal24] = useState(true)
  const [neuvoo, setNeuvoo] = useState(true)
  const [jobCrawler, setJobCrawler] = useState(true)
  const [jobtonic, setJobtonic] = useState(true)
  const [metajob, setMetajob] = useState(true)
  const [trabajo, setTrabajo] = useState(true)
  const [jobisjob, setJobisjob] = useState(true)
  const [jooble, setJooble] = useState(true)
  const [mitula, setMitula] = useState(true)
  const [trovit, setTrovit] = useState(true)
  const [jobkralle, setJobkralle] = useState(true)
  const [jopago, setJopago] = useState(true)
  const [yakaz, setYakaz] = useState(true)

  useEffect(() => {
    if (initialValues) {
      setAdzuna(initialValues.adzuna)
      setJobNinja(initialValues.jobNinja)
      setKazini(initialValues.kazini)
      setMeega(initialValues.meega)
      setAktuelle_jobs_de(initialValues.aktuelle_jobs_de)
      setJobrapido(initialValues.jobrapido)
      setKimeta_Free(initialValues.kimeta_free)
      setNjobs(initialValues.nJobs)
      setHerojob(initialValues.herojob)
      setJobroller_free(initialValues.jobroller_free)
      setLocal24(initialValues.local24)
      setNeuvoo(initialValues.neuvoo)
      setJobCrawler(initialValues.jobCrawler)
      setJobtonic(initialValues.jobtonic)
      setMetajob(initialValues.metajob)
      setTrabajo(initialValues.trabajo)
      setJobisjob(initialValues.jobisjob)
      setJooble(initialValues.jooble)
      setMitula(initialValues.mitula)
      setTrovit(initialValues.trovit)
      setJobkralle(initialValues.jobkralle)
      setJopago(initialValues.jopago)
      setYakaz(initialValues.yakaz)
    }
  }, [initialValues])

  useEffect(() => {
    const freeBundleJobBoards: TfreeBundleJobBoards = {
      adzuna,
      jobNinja,
      kazini,
      meega,
      aktuelle_jobs_de,
      jobrapido,
      kimeta_free,
      njobs,
      herojob,
      jobroller_free,
      local24,
      neuvoo,
      jobCrawler,
      jobtonic,
      metajob,
      trabajo,
      jobisjob,
      jooble,
      mitula,
      trovit,
      jobkralle,
      jopago,
      yakaz,
    }
    setFreeBundleJobBoards(freeBundleJobBoards)
  }, [
    adzuna,
    jobNinja,
    kazini,
    meega,
    aktuelle_jobs_de,
    jobrapido,
    kimeta_free,
    njobs,
    herojob,
    jobroller_free,
    local24,
    neuvoo,
    jobCrawler,
    jobtonic,
    metajob,
    trabajo,
    jobisjob,
    jooble,
    mitula,
    trovit,
    jobkralle,
    jopago,
    yakaz,
    setFreeBundleJobBoards,
  ])

  return (
    <div className='JobBoards'>
      <h2>Free Bundle Jobboards</h2>
      <div className='inputs'>
        <CheckboxInput checked={adzuna} onChange={setAdzuna} label='Adzuna' uniqueId={uniqueId} />
        <CheckboxInput
          checked={jobNinja}
          onChange={setJobNinja}
          label='JobNinja'
          uniqueId={uniqueId}
        />
        <CheckboxInput checked={kazini} onChange={setKazini} label='Kazini' uniqueId={uniqueId} />
        <CheckboxInput checked={meega} onChange={setMeega} label='NextJobs' uniqueId={uniqueId} />
        <CheckboxInput
          checked={aktuelle_jobs_de}
          onChange={setAktuelle_jobs_de}
          label='AktuelleJobs'
          uniqueId={uniqueId}
        />
        <CheckboxInput
          checked={jobrapido}
          onChange={setJobrapido}
          label='Jobrapido'
          uniqueId={uniqueId}
        />
        <CheckboxInput
          checked={kimeta_free}
          onChange={setKimeta_Free}
          label='Kimeta Free'
          uniqueId={uniqueId}
        />
        <CheckboxInput checked={njobs} onChange={setNjobs} label='nJobs' uniqueId={uniqueId} />
        <CheckboxInput
          checked={herojob}
          onChange={setHerojob}
          label='herojob.de'
          uniqueId={uniqueId}
        />
        <CheckboxInput
          checked={jobroller_free}
          onChange={setJobroller_free}
          label='jobroller-free'
          uniqueId={uniqueId}
        />
        <CheckboxInput
          checked={local24}
          onChange={setLocal24}
          label='local24'
          uniqueId={uniqueId}
        />
        <CheckboxInput
          checked={neuvoo}
          onChange={setNeuvoo}
          label='talent.com'
          uniqueId={uniqueId}
        />
        <CheckboxInput
          checked={jobCrawler}
          onChange={setJobCrawler}
          label='jobCrawler'
          uniqueId={uniqueId}
        />
        <CheckboxInput
          checked={jobtonic}
          onChange={setJobtonic}
          label='jobtonic'
          uniqueId={uniqueId}
        />
        <CheckboxInput
          checked={metajob}
          onChange={setMetajob}
          label='metajob'
          uniqueId={uniqueId}
        />
        <CheckboxInput
          checked={trabajo}
          onChange={setTrabajo}
          label='trabajo.org'
          uniqueId={uniqueId}
        />
        <CheckboxInput
          checked={jobisjob}
          onChange={setJobisjob}
          label='jobisJob'
          uniqueId={uniqueId}
        />
        <CheckboxInput checked={jooble} onChange={setJooble} label='jooble' uniqueId={uniqueId} />
        <CheckboxInput checked={mitula} onChange={setMitula} label='mitula' uniqueId={uniqueId} />
        <CheckboxInput checked={trovit} onChange={setTrovit} label='trovit' uniqueId={uniqueId} />
        <CheckboxInput
          checked={jobkralle}
          onChange={setJobkralle}
          label='jobkralle'
          uniqueId={uniqueId}
        />
        <CheckboxInput checked={jopago} onChange={setJopago} label='jopago' uniqueId={uniqueId} />
        <CheckboxInput checked={yakaz} onChange={setYakaz} label='yakaz.com' uniqueId={uniqueId} />
      </div>
    </div>
  )
}

export default JobBoards
