import { configureStore } from '@reduxjs/toolkit'

import { apiSlice } from '../api/api'
import editFeed from '../pages/feedCRUD/editFeed/reduxStates'
import auth from './slices/auth'
import general from './slices/general'
import user from './slices/user'

export const store = configureStore({
  reducer: {
    general,
    user,
    editFeed,
    auth,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), apiSlice.middleware],
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
