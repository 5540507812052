import { useState } from 'react'

interface Iprops {
  serverResponse: Record<string, unknown>
  isError: boolean
}
const ResponseResult: React.FC<Iprops> = ({ serverResponse, isError }) => {
  const [show, setShow] = useState(false)
  const handleOpen = () => {
    setShow(!show)
  }

  return (
    <div
      className='PreviewEdited componentContainer'
      style={{ backgroundColor: isError ? 'red' : '#009100' }}
    >
      <div onClick={handleOpen}>Response: {show ? '-' : '+'}</div>
      <div className='responseResult'></div>
      {show && <pre>{JSON.stringify(serverResponse, null, 2)}</pre>}
    </div>
  )
}
export default ResponseResult
