import { DividerLine } from '../../../../components/atoms/lines/DividerLine'
import SelectBox from '../../../../components/organisms/selectBox/SelectBox'
import TextInput from '../../../../components/organisms/textInput/TextInput'
import { Tauthorization, TauthorizationConfigs } from '../../../../types/Tfeeds'
import { TemptyObject } from '../../../../types/Tgeneral'
import useAllHooks from './hooksAndStates'
import './style.scss'

const authMethodList = ['none', 'basic']

interface Iprops {
  authorization?: Tauthorization
  setAuthorization: (e: Tauthorization) => void
  authorizationConfigs: TauthorizationConfigs
  setAuthorizationConfigs: (e: { username: string; password: string } | TemptyObject) => void
}

export const FeedAuth: React.FC<Iprops> = ({
  setAuthorizationConfigs,
  authorization,
  setAuthorization,
}) => {
  const { uniqueId, setUserName, setPassword } = useAllHooks(
    setAuthorizationConfigs,
    authorization,
    setAuthorization,
  )

  return (
    <div className='FeedAuth'>
      <DividerLine />
      <div className=''>
        <SelectBox
          label='Authorization'
          onChange={setAuthorization}
          selectList={authMethodList}
          uniqueId={uniqueId}
        />
      </div>
      {authorization === 'basic' && (
        <>
          <TextInput
            label='Username'
            onChange={(e) => setUserName(e.currentTarget.value)}
            uniqueId={uniqueId}
          />
          <TextInput
            label='Password'
            onChange={(e) => setPassword(e.currentTarget.value)}
            uniqueId={uniqueId}
            type='password'
          />
        </>
      )}
    </div>
  )
}
