import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import TextInput from '../../../../components/organisms/textInput/TextInput'
import { ThtmlSource, TreaderConfig } from '../../../../types/Tfeeds'
import { TmappingConfigs } from '../../../../types/Tmappings'
import { MappingFields } from '../../sharedComponents/mappingFields/MappingFields'
import defaultConfigJson from '../configs/defaultConfigJson'
import { setReaderConfig } from '../reduxStates'

interface Iprops {
  htmlSource: ThtmlSource
}

const GenericJson: React.FC<Iprops> = ({ htmlSource }) => {
  const [jobsContext, setJobsContext] = useState('jobs')
  const [mappingConfigs, setMappingConfigs] = useState<TmappingConfigs>()
  const uniqueId = '67988'

  const dispatch = useDispatch()

  useEffect(() => {
    if (mappingConfigs) {
      const readerConfig: TreaderConfig = { jobsContext, ...mappingConfigs }
      dispatch(setReaderConfig(readerConfig))
    }
  }, [dispatch, jobsContext, mappingConfigs])

  return (
    <div className='GenericJson'>
      <div className='title'>
        <h2>JSON mappings</h2>
        <Link target='_blank' to='/mappinghelperjson' className='help'>
          <h3>Help</h3>
        </Link>
      </div>
      <div className='useDefaults'>
        <button className='buttonBase' onClick={(e) => setDefaultMappingConfigs(e)}>
          Use default configs
        </button>
      </div>
      <div className='jobList'>
        <TextInput
          label='Job list path'
          uniqueId={uniqueId}
          onChange={(e) => setJobsContext(e.currentTarget.value)}
          value={jobsContext}
          required={false}
        />
        <p>
          Choose the path which the list of jobs is located in response data. If the response gives
          directy the list of jobs, please fill this field as empty (no dots are needed)
        </p>
      </div>
      <MappingFields
        htmlSource={htmlSource}
        setConfigs={setMappingConfigs}
        initialValues={mappingConfigs}
      />
    </div>
  )

  function setDefaultMappingConfigs(e: { preventDefault: () => void }) {
    e.preventDefault()

    const d = defaultConfigJson
    setJobsContext(d.jobsContext)
    const defaultMappingConfigs: TmappingConfigs = {
      id: d.id,
      title: d.title,
      publishDate: d.publishDate,
      showUrl: d.showUrl,
      embeddedHtml: d.embeddedHtml,
      referenceNumber: d.referenceNumber,
      company: d.company,
      applyUrl: d.applyUrl,
      applyEmail: d.applyEmail,
      locationContext: d.locationContext,
      industry: {
        id: d.industry.id,
        title: d.industry.title,
      },
      seniority: {
        id: d.seniority.id,
        title: d.seniority.title,
      },
      employmentStartDate: d.employmentStartDate,
      headcount: d.headcount,
      keywords: d.keywords,
      language: d.language,
      fieldOfWork: {
        id: d.fieldOfWork.id,
        title: d.fieldOfWork.title,
      },
      contractType: {
        id: d.contractType.id,
        title: d.contractType.title,
      },
      positionType: { id: d.positionType.id, title: d.positionType.title },
      location: {
        city: d.locations.city,
        countryCode: d.locations.countryCode,
        country: d.locations.country,
        street: d.locations.street,
        streetNumber: d.locations.streetNumber,
        region: d.locations.region,
        zip: d.locations.zip,
      },
      salary: {
        minimum: d.salary.minimum,
        maximum: d.salary.maximum,
        currency: d.salary.currency,
        interval: d.salary.interval,
      },
      contact: {
        name: d.contact.name,
        email: d.contact.email,
        phone: d.contact.phone,
        url: d.contact.url,
      },
      content: {
        hiringOrganizationTitle: d.content.hiringOrganizationTitle,
        hiringOrganization: d.content.hiringOrganization,
        responsibilitiesTitle: d.content.responsibilitiesTitle,
        responsibilities: d.content.responsibilities,
        qualificationsTitle: d.content.qualificationsTitle,
        qualifications: d.content.qualifications,
        incentives: d.content.incentives,
        incentivesTitle: d.content.incentivesTitle,
        contactTitle: d.content.contactTitle,
        contact: d.content.contact,
      },
      branding: {
        primaryColor: d.branding.primaryColor,
        secondaryColor: d.branding.secondaryColor,
        headerImage: d.branding.headerImage,
        sliderImages: d.branding.sliderImages,
        backgroundImage: d.branding.backgroundImage,
        video: d.branding.video,
        logo: d.branding.logo,
      },
    }

    setMappingConfigs(defaultMappingConfigs)
  }
}

export default GenericJson
