import { useEffect, useState } from 'react'

import { Tauthorization } from '../../../../types/Tfeeds'
import { TemptyObject } from '../../../../types/Tgeneral'

const useAllHooks = (
  setAuthorizationConfigs: (e: { username: string; password: string } | TemptyObject) => void,
  authorization: Tauthorization,
  setAuthorization: (e: Tauthorization) => void,
) => {
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')

  const uniqueId = '67988'

  useEffect(() => {
    setAuthorization(authorization)
    if (authorization === 'basic') setAuthorizationConfigs({ username, password })

    if (authorization === 'none') {
      setUserName('')
      setPassword('')
      setAuthorizationConfigs({})
    }
    if (authorization !== 'basic' && authorization !== 'none')
      throw new Error('authorization method not supported')
  }, [username, password, setAuthorizationConfigs, authorization, setAuthorization])

  return {
    uniqueId,

    username,
    setUserName,
    password,
    setPassword,
  }
}

export default useAllHooks
