import { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { useAddUserMutation, useGetUsersListQuery } from '../../api/api'
import LoadingSpinner from '../../components/atoms/loadingSpinner/LoadingSpinner'
import SelectBox from '../../components/organisms/selectBox/SelectBox'
import TextInput from '../../components/organisms/textInput/TextInput'
import { TresponseError } from '../../types/Tgeneral'
import { ISingleUser, SingleUser } from './SingleUser'
import { isEmailValid, isPasswordValid } from './utils'

export const userRoleList = ['admin', 'view', 'manager']
const uniqueId = '23551'
const notify = (message: string, autoClose = 3000) => toast(message, { autoClose })

export const Users: React.FC = () => {
  const [showUsers, setShowUsers] = useState(false)

  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState('view')

  const {
    data: usersList,
    isError: isErrorUsersList,
    isLoading: isLoadingUsersList,
  } = useGetUsersListQuery(null)
  const [
    addUser,
    { isLoading: isLoadingAddUser, error: isErrorAddUser, isSuccess: isSuccessAddUser },
  ] = useAddUserMutation()

  useEffect(() => {
    if (isErrorAddUser) {
      const errorObject: TresponseError = { ...isErrorAddUser } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }
    isSuccessAddUser && notify('Created successfully!')
    isLoadingAddUser && notify('Creating...', 700)
  }, [isErrorAddUser, isSuccessAddUser, isLoadingAddUser])

  return (
    <div className='Users pageContainerWide'>
      <div className='usersListContainer'>
        <div className='accordion' onClick={() => setShowUsers(!showUsers)}>
          <div className='accordionTitle'>Manage users</div>
          <div className='hideShow'>{showUsers ? 'hide' : 'show'}</div>
        </div>
        <div className='items' style={{ display: showUsers ? 'block' : 'none' }}>
          {isErrorUsersList && <h1>Error fetching users list</h1>}
          {isLoadingUsersList && <LoadingSpinner />}
          {showUsers &&
            usersList &&
            usersList.map((user: ISingleUser) => (
              <SingleUser
                key={user.id}
                id={user.id}
                username={user.username}
                createdAt={user.createdAt}
                role={user.role}
              />
            ))}
        </div>
      </div>
      <div className='createUser'>
        <h1>Create user</h1>
        <form onSubmit={handleCreateUser}>
          <TextInput
            label='Username'
            onChange={(e) => setUserName(e.currentTarget.value)}
            uniqueId={uniqueId}
            value={username}
          />
          <TextInput
            label='Password'
            onChange={(e) => setPassword(e.currentTarget.value)}
            uniqueId={uniqueId}
            value={password}
            type='password'
          />
          <SelectBox
            label='Role'
            onChange={setRole}
            uniqueId={uniqueId}
            defaultValue={role}
            selectList={userRoleList}
          />
          <div className='infos'>
            <div className='deviderLine'></div>

            <p>* Username must be a valid Email</p>
            <p>
              * Upassword must have at least a symbol, upper and lower case letters and a number and
              total of 12 charachters
            </p>
          </div>
          <button className='buttonBase createNewUserButton' disabled={isLoadingAddUser}>
            Create new user
          </button>
        </form>
        <ToastContainer limit={3} position='top-center' />
      </div>
    </div>
  )
  async function handleCreateUser(event: { preventDefault: () => void }) {
    event.preventDefault()
    if (!isEmailValid(username)) {
      notify('Email not valid')
      return
    }
    if (!isPasswordValid(password)) {
      notify('Password not valid')
      return
    }
    const newUserData = { username, password, role }
    await addUser(newUserData)
  }
}
