import { Link } from 'react-router-dom'

import { LinkIcon } from '../../../../components/atoms/icons/linkIcon/LinkIcon'
import { removeMiliSeconds } from '../../../../util/tools/tools'
import './style.scss'

export const SingleJobPosting: React.FC<IjobPosting> = ({
  id,
  title,
  state,
  feedId,
  externalImportId,
  externalExportId,
  importTime,
  updateTime,
  unpublishTime,
  publishedUrl,
  expirationDate,
}) => {
  return (
    <ul className='SingleJobPosting row'>
      <li>{id}</li>
      <li>{feedId}</li>
      <Link to={`/feeds/jobpostings/${id}`}>
        <li className='title'>{title}</li>
      </Link>
      <li style={{ color: statusColorizer(state) }}>{state}</li>
      <li className='externalExport'>
        {externalExportId && (
          <a
            style={{ cursor: 'pointer', color: 'blue' }}
            target='_blank'
            href={publishedUrl}
            rel='noreferrer'
          >
            {externalExportId}
            <LinkIcon />
          </a>
        )}
      </li>
      <li className='externalImport'>{externalImportId}</li>
      <li>{removeMiliSeconds(importTime)}</li>
      <li>{removeMiliSeconds(updateTime)}</li>
      <li>{removeMiliSeconds(unpublishTime)}</li>
      <li>{removeMiliSeconds(unpublishTime)}</li>
      <li>{removeMiliSeconds(expirationDate)}</li>
    </ul>
  )
}

export function statusColorizer(state: string) {
  switch (state) {
    case 'prepared':
      return 'brown'
    case 'published':
      return 'green'
    case 'unpublished':
      return 'grey'
  }
}

export interface IjobPosting {
  id: number
  title: string
  state: 'prepared' | 'published' | 'unpublished'
  feedId: number
  externalImportId: string
  externalExportId: string
  importTime: string
  updateTime: string
  unpublishTime: string
  publishedUrl: string
  expirationDate: string
}
