interface Iprops {
  uniqueId: string
  onChange: (e: any) => void
  label: string
  required?: boolean
  defaultValue?: string
  selectList: string[]
  idAttr?: string
  styleType?: 'defaultSelectBox' | 'subFormSelectBox'
  value?: any
}
const SelectBox: React.FC<Iprops> = ({
  uniqueId,
  selectList,
  onChange,
  label,
  required = true,
  defaultValue,
  idAttr = label,
  styleType = 'defaultSelectBox',
}) => {
  // toConsider
  const unique = `${idAttr}-${uniqueId}`

  return (
    <div className={`SelectBox ${styleType}`}>
      <label htmlFor={unique}>{label}</label>
      <select
        defaultValue={defaultValue}
        onChange={(e) => onChange(e.target.value)}
        id={unique}
        required={required}
      >
        {selectList.map((i: string) => (
          <option key={i} value={i}>
            {i}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectBox
