import ObjectInput from '../../../../../components/organisms/objectInput/ObjectInput'
import { IpropsPublisher } from '../../../../../types/Tfeeds'
import useAllHooks from './hooksAndStates'

export const Other: React.FC<IpropsPublisher> = ({
  publisherConfig,
  defaultPublisher,
  publisher,
}) => {
  const { uniqueId, setConfigs, extrasDefault } = useAllHooks(
    publisherConfig,
    defaultPublisher,
    publisher,
  )
  if (publisher !== 'jass' && publisher !== 'schalttool') {
    return (
      <div className='Other publisherSelected'>
        <ObjectInput
          defaultValue={extrasDefault}
          onChange={setConfigs}
          uniqueId={uniqueId}
          required
        />
      </div>
    )
  } else {
    return null
  }
}
