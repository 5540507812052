// import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const generalSlice = createSlice({
  name: 'general',
  initialState: {},
  reducers: {},
})

export default generalSlice.reducer
