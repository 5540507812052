import { useNavigate } from 'react-router-dom'

const BackButton: React.FC = () => {
  const navigate = useNavigate()
  return (
    <button
      className='BackButton'
      type='reset'
      onClick={() => {
        navigate(-1)
      }}
    >
      <img src={require('./backButton.png')} />
    </button>
  )
}

export default BackButton
