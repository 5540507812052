import { SetStateAction, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'

import { useLoginUserMutation } from '../../../api/api'
import { setToken } from '../../../redux/slices/auth'
import { setIsUserLoggedIn, setUserId, setUserName, setUserRole } from '../../../redux/slices/user'
import { Tresponse, TresponseError } from '../../../types/Tgeneral'
import LoadingSpinner from '../../atoms/loadingSpinner/LoadingSpinner'

export interface TuserData {
  username: string
  password: string
}

const notify = (message: string, autoClose = 3000) => toast(message, { autoClose })

const Login: React.FC = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [buttonClass, setButtonClass] = useState('buttonBase')

  const [loginUser, { error, isLoading }] = useLoginUserMutation()

  useEffect(() => {
    if (email.length > 0 && password.length > 0) {
      setButtonClass('buttonBase')
    } else {
      setButtonClass('buttonBase disabled')
    }
  }, [email, password])

  useEffect(() => {
    if (error) {
      const errorObject: TresponseError = { ...error } // prevents typescript errors
      errorObject?.error && notify(errorObject.error)
    }
  }, [error])

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      !validateButton() || LoginService(event)
    }
  }

  return (
    <div className='Login'>
      <div className='LoginContainer'>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Form onSubmit={LoginService} onKeyDown={handleKeyPress}>
            <Form.Group controlId='email' className='loginInput'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                type='email'
                value={email}
                onChange={(e: { target: { value: SetStateAction<string> } }) =>
                  setEmail(e.target.value)
                }
              />
            </Form.Group>
            <Form.Group controlId='password' className='loginInput'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                value={password}
                onChange={(e: { target: { value: SetStateAction<string> } }) =>
                  setPassword(e.target.value)
                }
              />
            </Form.Group>
            <button className={buttonClass} type='submit' disabled={!validateButton()}>
              Login
            </button>
          </Form>
        )}
      </div>
      <ToastContainer limit={3} position='top-center' />
    </div>
  )

  async function LoginService(event: { preventDefault: () => void }) {
    event.preventDefault()
    const userData: TuserData = { username: email, password }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: Tresponse = await loginUser(userData)

    if (response?.data?.token) {
      const { token, userId, username, role } = response.data
      localStorage.token = token
      dispatch(setToken(token))

      localStorage.username = username
      localStorage.userRole = role
      dispatch(setIsUserLoggedIn(true))
      dispatch(setUserId(userId))
      dispatch(setUserName(username))
      dispatch(setUserRole(role))
      window.location.assign('/')
    } else {
      notify(response.data.message)
      dispatch(setIsUserLoggedIn(false))
    }
    return null
  }

  function validateButton() {
    return email.length > 0 && password.length > 0
  }
}

export default Login
