import { ErrorField } from '../../../../../components/atoms/copyErrorMsg/CopyErrorMsg'
import { colorizeImportStatus, removeMiliSeconds } from '../../../../../util/tools/tools'

export const JobPostingImportElement: React.FC<IJobPostingImportElement> = ({
  id,
  importRunId,
  externalImportId,
  action,
  importTime,
  status,
  errorMessage,
}) => {
  return (
    <div className='JobPostingImportElement row'>
      <ul>
        <li>{id}</li>
        <li>{importRunId}</li>
        <li className='externalImport'>{externalImportId}</li>
        <li>{action}</li>
        <li>{removeMiliSeconds(importTime)}</li>
        <li style={{ color: colorizeImportStatus(status) }}>{status}</li>
        <li>
          <ErrorField errorMessage={errorMessage} />
        </li>
      </ul>
    </div>
  )
}

export interface IJobPostingImportElement {
  id: number
  importRunId: string
  externalImportId: string
  action: 'publish' | 'update' | 'unpublish'
  importTime: string
  status: 'planned' | 'error' | 'processed' | 'pending'
  errorMessage: string
}
