import { DividerLine } from '../../../../components/atoms/lines/DividerLine'
import JsonCodeView from '../../../../components/organisms/codeView/JsonCodeView'
import {
  jobsContextDirectArraySample,
  jobsContextSample,
  jsonLocationsSample,
} from './SampleXmlJsonData'

const MappingHelperJson: React.FC = () => {
  return (
    <div className='MappingHelper pageContainer'>
      <h1>Json path mapping guide</h1>
      <h3>Jobs list context</h3>
      <JsonCodeView json={jobsContextSample} />
      <h3>In the example above:</h3>
      <h4>Jobs list context is the array which contains all of job postings, which is:</h4>
      <p>
        <span>Job list context: </span>
        <span>stellen</span>
      </p>
      <h4>But sometimes the response data gives directry the list of jobs</h4>
      <JsonCodeView json={jobsContextDirectArraySample} />
      <h4>So we leave this field empty!</h4>
      <DividerLine />
      <h3>Root fields</h3>
      <h4>Earlier we have defined the job path, so to map the Id, we can easily input:</h4>
      <p>
        <span>id: </span>
        <span>handle</span>
      </p>
      <DividerLine />
      <h3>Locations</h3>
      <JsonCodeView json={jsonLocationsSample} />
      <h4>
        Its possible to have multiple locations, so first, in field &apos;locations&apos; we define
        the path which includes all locations, and then the child path for each item (ex:
        zip,city,...)
      </h4>
      <p>
        <span>locations: </span>
        <span>allLocations</span>
      </p>
      <p>
        <span>city: </span>
        <span>city</span>
      </p>
      <p>
        <span>zip:</span>
        <span>zipCode</span>
      </p>
    </div>
  )
}

export default MappingHelperJson
