import ObjectInput from '../../../../../components/organisms/objectInput/ObjectInput'
import { IpropsPublisher } from '../../../../../types/Tfeeds'
import useAllHooks from './hooksAndStates'

export const Debug: React.FC<IpropsPublisher> = ({
  publisherConfig,
  defaultPublisher,
  publisher,
}) => {
  const { uniqueId, setConfigs, extrasDefault } = useAllHooks(
    publisherConfig,
    defaultPublisher,
    publisher,
  )
  return (
    <div className='Debug publisherSelected'>
      <ObjectInput
        defaultValue={extrasDefault}
        onChange={setConfigs}
        uniqueId={uniqueId}
        required
      />
    </div>
  )
}
