import { TimportRunStatus } from '../../../../../types/Tfeeds'
import { removeMiliSeconds } from '../../../../../util/tools/tools'

interface Iprops {
  nextImportRun: { isActive: boolean; status: TimportRunStatus; nextImportRun: string }
}

export const NextImportRun: React.FC<Iprops> = ({ nextImportRun }) => {
  if (nextImportRun?.status === 'running') return <h3 className='running'>Running</h3>

  return (
    <div className='nextImportRun'>
      <h2>Next scheduled import run: </h2>
      {!nextImportRun?.isActive ? (
        <div>Feed is not active!</div>
      ) : (
        <div className='next'>
          <div className='status'>Prepared : </div>
          <div className='time'>{removeMiliSeconds(nextImportRun?.nextImportRun)}</div>
        </div>
      )}
    </div>
  )
}
