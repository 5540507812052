import { useState } from 'react'

import { DividerLine } from '../../../../../components/atoms/lines/DividerLine'
import SelectBox from '../../../../../components/organisms/selectBox/SelectBox'
import { IpropsAirtablePublisher } from '../../../../../types/Tfeeds'
import { TmappingConfigs } from '../../../../../types/Tmappings'
import { MappingFields } from '../../../sharedComponents/mappingFields/MappingFields'
import useAllStates from './hooksAndStates'

const unpublishMethods = ['delete record', 'set status as unpublish']
const mappingParserList = ['json', 'xml']

export const Airtable: React.FC<IpropsAirtablePublisher> = ({ publisher, htmlSource }) => {
  const [mappingsConfigs, setMappingsConfigs] = useState<TmappingConfigs>()

  const { uniqueId, unpublishMethod, setUnpublishMethod, setMappingParser } = useAllStates(
    publisher,
    mappingsConfigs,
  )

  if (publisher === 'airtable') {
    return (
      <div className='publisherSelected '>
        <div className='SchalttoolSelected inputContainer'>
          <DividerLine />
          <SelectBox
            label='Unpublish method'
            onChange={setUnpublishMethod}
            uniqueId={uniqueId}
            selectList={unpublishMethods}
            defaultValue={unpublishMethod}
          />
        </div>
        <SelectBox
          label='Mapping parser'
          onChange={setMappingParser}
          uniqueId={uniqueId}
          selectList={mappingParserList}
        />
        <DividerLine />
        <MappingFields htmlSource={htmlSource} setConfigs={setMappingsConfigs} />
      </div>
    )
  } else {
    return null
  }
}
