import { Link } from 'react-router-dom'

import { ErrorField } from '../../../../components/atoms/copyErrorMsg/CopyErrorMsg'
import { colorizeImportStatus } from '../../../../util/tools/tools'
import './style.scss'

export const SingleJobPostingImport: React.FC<IsingleJobPostingImport> = ({
  id,
  feedId,
  importRunId,
  jobPostingId,
  externalImportId,
  action,
  status,
  title,
  errorMessage,
}) => {
  return (
    <div className='SingleJobPostingImport row'>
      <ul>
        <li>{id}</li>
        <li>{feedId}</li>
        <li>{importRunId}</li>
        <li className='externalImport'>{externalImportId}</li>
        <li>{action}</li>
        <li style={{ color: colorizeImportStatus(status) }}>{status}</li>
        <Link to={`/feeds/jobpostings/${jobPostingId}`}>
          <li className='blue'>{jobPostingId}</li>
        </Link>
        <Link to={`/feeds/jobpostings/${jobPostingId}`}>
          <li className='blue'>{title}</li>
        </Link>
        <li>
          <ErrorField errorMessage={errorMessage} />
        </li>
      </ul>
    </div>
  )
}

export interface IsingleJobPostingImport {
  id: number
  feedId: string
  importRunId: string
  jobPostingId: string
  externalImportId: string
  action: 'publish' | 'update' | 'unpublish'
  status: 'planned' | 'error' | 'processed' | 'pending'
  title: string
  errorMessage: string
}
