/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { TclientId, Texpires, TschalttoolConfig } from '../../../../../types/Tfeeds'
import { TfreeBundleJobBoards } from '../../../../../types/Tpublishers'
import { setSchalttoolConfig } from '../../reduxStates'

const useAllHooks = () => {
  const dispatch = useDispatch()
  const uniqueId = '23948'

  const [clientId, setClientId] = useState<TclientId>(0)
  const [expires, setExpires] = useState<Texpires>(30)
  const [autoPublish, setAutoPublish] = useState(true)
  const [jopago, setJopago] = useState(false)
  const [kleinanzeigen_onlyjobs, setKleinanzeigen_onlyjobs] = useState(false)
  const [freeBundle, setFreeBundle] = useState(false)
  const [rekruter, setRekruter] = useState(false)
  const [onlyjobs, setOnlyjobs] = useState(false)
  const [indeed_campaign, setIndeed_campaign] = useState(false)
  const [ebay_kleinanzeigen, setEbay_kleinanzeigen] = useState(false)
  const [xing, setXing] = useState(false)
  const [extraInputs, setExtraInputs] = useState('')
  const [freeBundleJobBoards, setFreeBundleJobBoards] = useState<TfreeBundleJobBoards>()

  useEffect(() => {
    !freeBundle && setFreeBundleJobBoards(undefined)
  }, [freeBundle, freeBundleJobBoards])

  useEffect(() => {
    const newSchalttoolConfig: TschalttoolConfig = {
      clientId,
      autopublish: autoPublish,
      expires: [expires, 'days'],
      context: {
        apis: {
          jopago,
          kleinanzeigen_onlyjobs,
          rekruter: rekruter,
          onlyjobs,
          indeed_campaign,
          ebay_kleinanzeigen,
          xing,
          free_bundle: freeBundle,
        },
      },
      extras: extraInputs,
    }
    freeBundleJobBoards &&
      Object.assign(newSchalttoolConfig.context, { free_bundle: { apis: freeBundleJobBoards } })

    dispatch(setSchalttoolConfig(newSchalttoolConfig))
  }, [
    dispatch,
    clientId,
    expires,
    autoPublish,
    jopago,
    freeBundle,
    rekruter,
    extraInputs,
    freeBundleJobBoards,
    onlyjobs,
    indeed_campaign,
    kleinanzeigen_onlyjobs,
    ebay_kleinanzeigen,
    xing,
  ])

  return {
    dispatch,
    uniqueId,
    clientId,
    setClientId,
    expires,
    setExpires,
    autoPublish,
    setAutoPublish,
    jopago,
    setJopago,
    freeBundle,
    setFreeBundle,
    rekruter,
    setRekruter,
    extraInputs,
    setExtraInputs,
    onlyjobs,
    setOnlyjobs,
    indeed_campaign,
    setIndeed_campaign,
    setFreeBundleJobBoards,
    kleinanzeigen_onlyjobs,
    setKleinanzeigen_onlyjobs,
    ebay_kleinanzeigen,
    setEbay_kleinanzeigen,
    xing,
    setXing,
  }
}

export default useAllHooks
