import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { TuserData } from '../components/templates/login/Login'
import { RootState } from '../redux/store'
import { Tfeed, TfeedListItem } from '../types/Tfeeds'
import { TemptyObject } from '../types/Tgeneral'

interface TeditFeedArgs {
  feedId: string | undefined
  editedData: Tfeed
}
export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
    credentials: 'include',
  }),
  tagTypes: [
    'feedDetails',
    'deleteFeed',
    'readersList',
    'publishersList',
    'feedsList',
    'importruns',
    'jobpostings',
    'jobpostingimports',
    'usersList',
    'templateList',
    'templateHtml',
    'jobPostingDetails',
    'feedStatistics',
  ],
  endpoints: (builder) => ({
    // single feed CRUD
    getFeedDetails: builder.query({
      query: (feedId) => ({ url: `/feeds/${feedId}` }),
      providesTags: ['feedDetails'],
    }),
    getFeedStatistics: builder.query({
      query: (feedId) => ({ url: `/feeds/${feedId}/statistics` }),
    }),
    addFeed: builder.mutation<TemptyObject, { newFeedData: Tfeed }>({
      query: (data) => ({ url: '/feeds', method: 'POST', body: data }),
      invalidatesTags: ['feedDetails', 'feedsList'],
    }),
    editFeed: builder.mutation<TemptyObject, TeditFeedArgs>({
      query: ({ feedId, editedData }) => ({
        url: `/feeds/${feedId}`,
        method: 'PATCH',
        body: { editedData },
      }),
      invalidatesTags: ['feedDetails'],
    }),
    triggerImportRun: builder.mutation({
      query: (feedId) => ({
        url: `/feeds/${feedId}/triggerImportRun`,
        method: 'POST',
      }),
      invalidatesTags: ['importruns'],
    }),
    triggerTestImportRun: builder.mutation({
      query: (feedId) => ({
        url: `/feeds/${feedId}/triggerTestImportRun`,
        method: 'POST',
      }),
      invalidatesTags: ['importruns'],
    }),
    deleteFeed: builder.mutation<TemptyObject, number>({
      query: (feedId) => ({ url: `/feeds/${feedId}`, method: 'DELETE' }),
      invalidatesTags: ['feedDetails', 'feedsList'],
    }),
    duplicateFeed: builder.mutation<TemptyObject, number>({
      query: (id) => ({ url: '/feeds/duplicate', method: 'POST', body: { id } }),
      invalidatesTags: ['feedDetails', 'feedsList'],
    }),
    // single feed import run
    singleFeedAllImportRuns: builder.query({
      query: (feedId) => ({ url: `/feeds/${feedId}/importruns` }),
      providesTags: ['importruns'],
    }),
    nextImportRun: builder.query({
      query: (feedId) => ({ url: `/feeds/${feedId}/nextImportRun` }),
    }),
    singleFeedLastImportRun: builder.query({
      query: (feedId) => ({ url: `/feeds/${feedId}/lastimportrun` }),
      providesTags: ['importruns'],
    }),
    abortImportRun: builder.mutation({
      query: (feedId) => ({
        url: `/feeds/${feedId}/abortImportRun`,
        method: 'POST',
      }),
      invalidatesTags: ['importruns'],
    }),
    // job postings
    singleFeedJobPostings: builder.query({
      query: (feedId) => ({ url: `/feeds/${feedId}/jobpostings` }),
      providesTags: ['jobpostings'],
    }),
    singleFeedJobPostingsStats: builder.query({
      query: (feedId) => ({ url: `/feeds/${feedId}/jobpostings/statistics` }),
    }),
    singleFeedJobPostingsStatsCurrentMonth: builder.query({
      query: (feedId) => ({ url: `/feeds/${feedId}/jobpostings/statistics/quotaUsage` }),
    }),
    singleFeedJobPostingImports: builder.query({
      query: (data) => ({
        url: `/feeds/${data.importRunId}/jobpostingimports`,
        params: data,
      }),
      providesTags: ['jobpostingimports'],
    }),

    jobPostingDetails: builder.query({
      query: (jobPostingId) => ({ url: `/feeds/jobPostings/${jobPostingId}` }),
      providesTags: ['jobPostingDetails'],
    }),

    // all feeds, readers & publishers list
    getFeedsList: builder.query<Array<TfeedListItem>, void>({
      query: () => ({ url: '/feeds' }),
      providesTags: ['feedsList'],
    }),
    getReadersList: builder.query<[string], void>({
      query: () => ({ url: '/feeds/readerslist' }),
      providesTags: ['readersList'],
    }),
    getPublishersList: builder.query<[string], void>({
      query: () => ({ url: '/feeds/publisherslist' }),
      providesTags: ['publishersList'],
    }),

    // User
    getUsersList: builder.query({
      query: () => ({ url: '/users' }),
      providesTags: ['usersList'],
    }),
    // // CRUD
    addUser: builder.mutation({
      query: (data) => ({ url: '/users', body: data, method: 'POST' }),
      invalidatesTags: ['usersList'],
    }),
    updateUser: builder.mutation({
      query: ({ id, newUserData }) => ({ url: `/users/${id}`, body: newUserData, method: 'PATCH' }),
      invalidatesTags: ['usersList'],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({ url: `/users/${id}`, method: 'DELETE' }),
      invalidatesTags: ['usersList'],
    }),

    // // authentication & authorization
    validateToken: builder.mutation<TemptyObject, void>({
      query: () => ({ url: '/credentials', method: 'POST' }),
    }),
    loginUser: builder.mutation<TemptyObject, TuserData>({
      query: (newUserData) => ({ url: '/user/login', method: 'POST', body: newUserData }),
    }),

    // templates
    getTemplateList: builder.query({
      query: () => ({ url: '/templates' }),
      providesTags: ['templateList'],
    }),
    getTemplateHtmlContent: builder.query({
      query: (name) => ({ url: `/templates/${name}` }),
      providesTags: ['templateHtml'],
    }),
    addTemplate: builder.mutation({
      query: (data) => ({
        url: `/templates/${data?.templateName}`,
        body: data.zipFile,
        method: 'POST',
      }),
      invalidatesTags: ['templateList', 'templateHtml'],
    }),
    deleteTemplate: builder.mutation<TemptyObject, string>({
      query: (templateName) => ({ url: `/templates/${templateName}`, method: 'DELETE' }),
      invalidatesTags: ['templateList', 'templateHtml'],
    }),
  }),
})

export const {
  useGetFeedDetailsQuery,
  useDuplicateFeedMutation,
  useTriggerTestImportRunMutation,
  useAbortImportRunMutation,
  useNextImportRunQuery,
  useTriggerImportRunMutation,
  useSingleFeedJobPostingsStatsCurrentMonthQuery,
  useSingleFeedJobPostingsStatsQuery,
  useGetFeedStatisticsQuery,
  useJobPostingDetailsQuery,
  useDeleteTemplateMutation,
  useGetTemplateHtmlContentQuery,
  useAddFeedMutation,
  useEditFeedMutation,
  useDeleteFeedMutation,
  useGetReadersListQuery,
  useGetPublishersListQuery,
  useValidateTokenMutation,
  useGetFeedsListQuery,
  useLoginUserMutation,
  useSingleFeedAllImportRunsQuery,
  useSingleFeedLastImportRunQuery,
  useSingleFeedJobPostingsQuery,
  useSingleFeedJobPostingImportsQuery,
  useGetUsersListQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useAddTemplateMutation,
  useGetTemplateListQuery,
} = apiSlice
