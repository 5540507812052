import { useState } from 'react'

import { Tfeed } from '../../../types/Tfeeds'

interface Iprops {
  editedData: Tfeed
}
const PreviewEdited: React.FC<Iprops> = ({ editedData }) => {
  const [show, setShow] = useState(false)
  function handleOpen() {
    setShow(!show)
  }

  return (
    <div className='PreviewEdited'>
      <div className='container' onClick={handleOpen}>
        Preview: {show ? 'hide' : 'show'}
      </div>
      {show && <pre>{JSON.stringify(editedData, null, 2)}</pre>}
    </div>
  )
}
export default PreviewEdited
