import { ThtmlSource } from '../../../../types/Tfeeds'
import GenericJson from './GenericJson'
import GenericXml from './GenericXml'

interface Iprops {
  reader: string
  htmlSource: ThtmlSource
}
const ReaderConfig: React.FC<Iprops> = ({ reader, htmlSource }) => {
  return (
    <div className='ReaderConfig'>
      {reader === 'generic-json' && <GenericJson htmlSource={htmlSource} />}
      {reader === 'generic-xml' && <GenericXml htmlSource={htmlSource} />}
      {reader === 'onlyjobs' && <GenericXml htmlSource={htmlSource} />}
    </div>
  )
}

export default ReaderConfig
