import { Link } from 'react-router-dom'

import { removeMiliSeconds } from '../../../../../util/tools/tools'

export const SingleRun: React.FC<IimportRun> = ({ id, status, startTime, endTime, result }) => {
  return (
    <ul className='SingleRun row'>
      <Link to={`/feeds/jobpostingimports/${id}`}>
        <li>{id}</li>
      </Link>
      <Link to={`/feeds/jobpostingimports/${id}`}>
        <li style={{ color: statusColorizer(status) }}>{status}</li>
      </Link>
      <Link to={`/feeds/jobpostingimports/${id}`}>
        <li>{removeMiliSeconds(startTime)}</li>
      </Link>
      <Link to={`/feeds/jobpostingimports/${id}`}>
        <li>{removeMiliSeconds(endTime)}</li>
      </Link>
      <li>
        <Result result={result} />
      </li>
    </ul>
  )
}

export function statusColorizer(status: string) {
  switch (status) {
    case 'error':
      return 'red'
    case 'running':
      return 'grey'
    case 'finished':
      return 'green'
    case 'planned':
      return 'grey'
  }
}

export interface IimportRun {
  id: number
  status: 'error' | 'running' | 'finished' | 'planned'
  startTime: string
  endTime: string
  errorMessage?: string
  result: string
}

function stringifyResult(result: string) {
  if (!result) return null

  try {
    const all = JSON.parse(result)
    const importResults = all?.importResults

    return importResults
  } catch (_error) {
    return 'error parsing JSON'
  }
}

interface IResult {
  result: string
}
const Result: React.FC<IResult> = ({ result }) => {
  const stringifiedResult = stringifyResult(result)

  return (
    <>
      {stringifiedResult ? (
        <div className='Result'>
          <span style={{ color: stringifiedResult?.processedImports ? 'green' : 'gray' }}>
            processed: {stringifiedResult.processedImports}
          </span>
          <span> -- </span>
          <span style={{ color: stringifiedResult?.failedImports ? 'red' : 'gray' }}>
            failed: {stringifiedResult.failedImports}
          </span>
          <span> -- </span>
          <span style={{ color: stringifiedResult?.unpublishErrors ? 'red' : 'gray' }}>
            unpublishErrors: {stringifiedResult.unpublishErrors}
          </span>
        </div>
      ) : null}
    </>
  )
}
