import ObjectInput from '../../../../../components/organisms/objectInput/ObjectInput'
import useAllHooks from './hooksAndStates'

interface Iprops {
  publisher: string
}
export const Debug: React.FC<Iprops> = ({ publisher }) => {
  const { setConfigs } = useAllHooks(publisher)
  return (
    <div className='Debug publisherSelected'>
      <ObjectInput defaultValue={'{}'} onChange={setConfigs} uniqueId={'85657'} required />
    </div>
  )
}
