import { Link, useParams } from 'react-router-dom'

import { useJobPostingDetailsQuery } from '../../../../../api/api'
import { LinkIcon } from '../../../../../components/atoms/icons/linkIcon/LinkIcon'
import LoadingSpinner from '../../../../../components/atoms/loadingSpinner/LoadingSpinner'
import { removeMiliSeconds } from '../../../../../util/tools/tools'
import { statusColorizer } from '../SingleJobPosting'
import { JobPostingImportElement } from './JobPostingImportElement'
import './style.scss'

export const JobPostingDetails: React.FC = () => {
  const { jobPostingId } = useParams()
  const { data, isError, isLoading, error } = useJobPostingDetailsQuery(jobPostingId)

  if (isError) {
    return <h1>{JSON.stringify(error, null, 2)}</h1>
  }

  return (
    <div className='JobPostingDetails pageContainer'>
      <div className='breadcrumbs'>
        <span>
          <Link to='/feeds'>
            <p>feeds</p>
          </Link>
        </span>
        <span> / </span>
        <Link to={`/editfeed/${data?.feedId}`}>
          <p>{data?.feedId}</p>
        </Link>
        <span> / </span>
        <Link to={`/feeds/jobpostings/${data?.feedId}/feed`}>
          <p>jobpostings</p>
        </Link>
        <span> / </span>
        <span>{data?.id}</span>
      </div>
      {isLoading && <LoadingSpinner />}
      <h1>{data?.title}</h1>
      <div className='table'>
        <div className='tableHeader'>
          <ul>
            <li>Id</li>
            <li>Feed</li>
            <li>State</li>
            <li>Ext Export</li>
            <li>Ext Import</li>
            <li>ImportTime</li>
            <li>UpdateTime</li>
            <li>UnpublishTime</li>
          </ul>
        </div>
        <div className='SingleJobPosting row'>
          <ul>
            <li>{data?.id}</li>
            <li>{data?.feedId}</li>
            <li style={{ color: statusColorizer(data?.state) }}>{data?.state}</li>
            <li className='externalExport'>
              {data?.externalExportId && (
                <a
                  style={{ cursor: 'pointer', color: 'blue' }}
                  target='_blank'
                  href={data?.publishedUrl}
                  rel='noreferrer'
                >
                  {data?.externalExportId}
                  <LinkIcon />
                </a>
              )}
            </li>
            <li className='externalImport'>{data?.externalImportId}</li>
            <li>{removeMiliSeconds(data?.importTime)}</li>
            <li>{removeMiliSeconds(data?.updateTime)}</li>
            <li>{removeMiliSeconds(data?.unpublishTime)}</li>
          </ul>
        </div>
      </div>
      <div className='JobPostingImports'>
        <h2>Job Posting Imports:</h2>
        <div className='table'>
          <div className='tableHeader'>
            <ul>
              <li>Id</li>
              <li>ImportRun</li>
              <li>Ext Import</li>
              <li>Action</li>
              <li>Import time</li>
              <li>Status</li>
              <li>ErrorMessage</li>
            </ul>
          </div>
          {isLoading && <LoadingSpinner />}
          {data?.jobPostingImport.map((jpi: Ijpi) => (
            <JobPostingImportElement
              key={jpi.id}
              id={jpi.id}
              importRunId={jpi.importRunId}
              externalImportId={jpi.externalImportId}
              action={jpi.action}
              importTime={jpi.importTime}
              status={jpi.status}
              errorMessage={jpi.errorMessage}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

interface Ijpi {
  id: number
  importRunId: string
  externalImportId: string
  action: 'publish' | 'update' | 'unpublish'
  importTime: string
  status: 'error' | 'planned' | 'processed' | 'pending'
  errorMessage: string
}
