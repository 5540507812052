import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useGetTemplateListQuery } from '../../api/api'
import LoadingSpinner from '../../components/atoms/loadingSpinner/LoadingSpinner'
import SingleTemplate from './singleTemplate/SingelTemplate'
import { Ttemplate } from './types'

const Templates: React.FC = () => {
  const [templates, setTemplates] = useState<Ttemplate[] | never[]>([])
  const { data, isError, isLoading, isSuccess } = useGetTemplateListQuery(null)

  useEffect(() => {
    isSuccess && setTemplates(data)
  }, [data, isSuccess])

  isError && <>Error: fetch template list list from server</>

  return (
    <div className='Templates pageContainer'>
      <h1>Templates</h1>
      <div className='buttonsContainer'>
        {localStorage.userRole !== 'view' && (
          <Link to='/addNewTemplate' className='AddNewFeed'>
            <button className='buttonBase'>Add new +</button>
          </Link>
        )}
      </div>
      <div className='table'>
        <div className='tableHeader'>
          <ul>
            <li>Name</li>
            <li>Html content</li>
            <li>Download zip</li>
            <li></li>
          </ul>
        </div>
        {isLoading && <LoadingSpinner />}
        {!!templates?.length &&
          templates.map((i: Ttemplate) => <SingleTemplate key={i} name={i} />)}
      </div>
    </div>
  )
}

export default Templates
