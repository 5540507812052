import { useDetectClickOutside } from 'react-detect-click-outside'
import { Link } from 'react-router-dom'

interface IpropsDropDownContainer {
  id: number | undefined | string
  name: string
  closeDropdown: () => void
  setDisplayDropdown: (e: boolean) => void
  displayDropdown: boolean
}
export const DropDownContainer: React.FC<IpropsDropDownContainer> = ({
  id,
  name,
  closeDropdown,
  setDisplayDropdown,
  displayDropdown,
}) => {
  const ref = useDetectClickOutside({ onTriggered: closeDropdown })

  return (
    <div className='clickable' onClick={() => setDisplayDropdown(!displayDropdown)} ref={ref}>
      . . .{displayDropdown && <AdvancedDropDown id={id} name={name} />}
    </div>
  )
}

interface IpropsAdvancedDropDown {
  id: number | undefined | string
  name: string
}
const AdvancedDropDown: React.FC<IpropsAdvancedDropDown> = ({ name }) => {
  return (
    <div className='AdvancedDropDown'>
      <ul>
        {localStorage.userRole !== 'view' && (
          <Link to={`delete/${name}`}>
            <li className='delete'>Delete</li>
          </Link>
        )}
      </ul>
    </div>
  )
}
