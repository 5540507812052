export const SearchIcon = () => {
  return (
    <div className='SearchIcon'>
      <img
        width='35px'
        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADV0lEQVR4nO1Zz29MURT+LLCzQiVYYG0pflsrKwlKSBr8AZKqaJow+s592i4tJKwQIk1jQ8Wa1gZtQvvO6Y/YKBt0Q9P60erImXdf52kr6XTuezNT8yU3ecmd+c49755z7rnfA6qooorEkEGwwYDPEvgugfsIPEaQXzoM+IsB94ZzwRkf/TUoNxD4gAF3GciUgWQXOaYI/NgH7y/1+kEY2EaQp9HiCDxtIM8J0tAC3pnBm/W30LtShz4byC4fgxcI0m1/G/3vSSuGt5bIieCUgYzbxUwYcKuPkXWL/b86RuA2Ak9aZ74R5CTSBEGuxEKkw2B441K5Mni7yUA6Y3yXkQYIfNW+wRl9ziK7wgWvBz5vwL9TcYbCcMqqQQ/BUdf8BnwscsaHnEBSiR3LiUsJhm1TPmeGtiRhIKpOHUgQGqr5nOGuBM6J3FuaJAxsRsLQ4hFWQskSgn0OiXOHnb6hVqQELc0mjIBHDtuO3Ck8Xcg5Ubzd3Dkzre2NPhdNSOBzNqyeIWUYSE9oW+qLJtMmz5I1OFldYbYb7Uu87YKsT8m0d3KyuoJsD+6xefLKARmPhQfU/PwwkMME/mjAHwy41vW8j/4auyOfi3bEQH4qWQbBqvlzuoDZHmnU9fx1jKy2jvxYFo4YyHjCocW1djGjhOCg63nfhpaBvHOW7HopQsrwMbTX2u5ZFuXXQG4WTaZignWkGymDwC+sI0eKJrNxmmtRnLQKS2pRRtY4IVW1w5bBNqQEA2m3jeoDZ6TXILst6fc023gCz3gY3O6YPGrlpdPVPX0hKDeBH0a24Bp67dTrp038JucGZu1ws3XiU2JqpOpOkfigQoFrfh98XLnDkOJDSBIq1UTO6M64CDMbTs2RghJWK65D0sg7E8aximxL5dLiEcuJudpwHZKG6k75nFFRgtsKOWesFtyuldDu8NcFRPCpVJzRAhCrZjYkcgJ1o16KNFm1a9ahz+FFiS9q7xQXsQ3kvmoDuuiSOaNQyUbVjkI+K+gdgyD3WjCwAzGU3Jl8WyH1BnyHwK/Djzu5+8wEQd4T5CWBb+iiMhhai3+gLJxxBdWWzRxnQkmKT6PS4FWdKVN41Z0pU3jVnSlT0ALnjH6gRSXC+zvMMqhkELiuYncC/xv+AC8FmJN5JvRqAAAAAElFTkSuQmCC'
      />
    </div>
  )
}
