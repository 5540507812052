import axios, { AxiosResponse } from 'axios'
import fileDownload from 'js-file-download'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { RootState } from '../../../redux/store'
import { Iprops } from '../types'
import { DropDownContainer } from './DropDownContainer'

const SingleTemplate: React.FC<Iprops> = ({ name }) => {
  const [displayDropdown, setDisplayDropdown] = useState(false)

  const { auth } = useSelector((state: RootState) => state)

  const closeDropdown = () => {
    setDisplayDropdown(false)
  }

  async function download() {
    const headers = { Authorization: `Bearer ${auth.token}` }

    const url = process.env.REACT_APP_SERVER_URL + `/templates/${name}/download`
    const config: any = {
      withCredentials: true,
      responseType: 'blob',
      headers,
    }
    const response: AxiosResponse = await axios.get(url, config)

    fileDownload(response.data, `${name}.zip`)
  }

  return (
    <div className='SingleTemplate SingleFeed row'>
      <ul className=''>
        <li>{name}</li>
        <li>
          <Link target={'_blank'} to={`htmlContent/${name}`}>
            Open
          </Link>
        </li>
        <li onClick={download} className='download'>
          Downlad
        </li>
        <li className='advancedButt'>
          <DropDownContainer
            id={name}
            name={name}
            closeDropdown={closeDropdown}
            setDisplayDropdown={setDisplayDropdown}
            displayDropdown={displayDropdown}
          />
        </li>
      </ul>
    </div>
  )
}

export default SingleTemplate
