import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { useDeleteFeedMutation, useGetFeedDetailsQuery } from '../../../api/api'
import LoadingSpinner from '../../../components/atoms/loadingSpinner/LoadingSpinner'
import TextInput from '../../../components/organisms/textInput/TextInput'
import { TresponseError } from '../../../types/Tgeneral'
import ResponseResult from '../editFeed/responseResult/ResponseResult'

const notify = (message: string, autoClose = 3000) => toast(message, { autoClose })

const DeleteFeed: React.FC = () => {
  const uniqueId = '95559'
  const { feedId } = useParams()
  const [serverResponse, setServerResponse] = useState({})
  const [feedNameInput, setFeedNameInput] = useState('')
  const [feedName, setFeedName] = useState('')
  const [formNotValid, setFormNotValid] = useState(false)

  const [
    deleteFeed,
    { isLoading: isLoadingDelete, isError, error, isSuccess, data: deleteResponse },
  ] = useDeleteFeedMutation()
  const { data: feedDetails, isLoading: isLoadingDetails } = useGetFeedDetailsQuery(feedId)

  useEffect(() => {
    feedDetails?.name && setFeedName(feedDetails.name)
  }, [feedDetails])

  useEffect(() => {
    if (error) {
      const errorObject: TresponseError = { ...error } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }
    if (isSuccess) {
      notify('Deleted successfully!')
      setTimeout(() => {
        window.location.href = './'
      }, 2000)
    }
    isLoadingDelete && notify('Deleting...', 700)
    deleteResponse && setServerResponse(deleteResponse)
    error && setServerResponse(error)
  }, [setServerResponse, error, isSuccess, isLoadingDelete, deleteResponse])

  isLoadingDetails && <LoadingSpinner />

  return (
    <div className='DeleteFeed pageContainer'>
      <div className='breadcrumbs'>
        <span>
          <Link to='/feeds'>
            <p>feeds</p>
          </Link>
        </span>
        <span> / </span>
        <span>
          <Link to={`/editfeed/${feedId}`}>
            <p>
              {feedName} : {feedId}
            </p>
          </Link>
        </span>
      </div>
      <h1>
        Delete Feed <span>{feedName}</span>
      </h1>
      <form action='' onSubmit={handleSubmit} className=''>
        <div className='componentContainer greyRoundContainer'>
          <h2>Feed details</h2>
          <div className=''>
            <pre>{JSON.stringify(feedDetails, null, 2)}</pre>
          </div>
        </div>
        <div className='deleteAssurance compnentContainer'>
          <h2>If you are sure to delete this feed, copy feed name here:</h2>
          <div className='greyRoundContainer'>
            <TextInput
              label='Feed Name'
              onChange={(e) => setFeedNameInput(e.currentTarget.value)}
              uniqueId={uniqueId}
              value={feedNameInput}
              onBlur={() => feedNameInput && feedNameInput !== feedName && setFormNotValid(true)}
              onFocus={() => setFormNotValid(false)}
              idAttr='FeedName'
            />
            <div className='compnentContainer formValidation'>
              {formNotValid && <p>Input name must be same as Feed name</p>}
            </div>
          </div>
        </div>
        <div className='buttons'>
          <button
            className='deleteButt buttonBase'
            type='submit'
            disabled={isLoadingDelete || feedNameInput !== feedName}
          >
            Delete
          </button>
        </div>
      </form>
      {!!Object.keys(serverResponse).length && (
        <ResponseResult serverResponse={serverResponse} isError={isError} />
      )}
      <ToastContainer limit={3} position='top-center' />
    </div>
  )
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    return await deleteFeed(Number(feedId))
  }
}

export default DeleteFeed
