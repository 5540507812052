/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  TclientId,
  Texpires,
  TpublisherConfig,
  TschalttoolConfig,
} from '../../../../../types/Tfeeds'
import { TfreeBundleJobBoards } from '../../../../../types/Tpublishers'
import { setSchalttoolConfig } from '../../reduxStates'

const useAllHooks = (
  defaultPublisher: string,
  publisherConfig: TpublisherConfig,
  publisher: string,
) => {
  const dispatch = useDispatch()
  const uniqueId = '56181'

  const [clientId, setClientId] = useState<TclientId>(0)
  const [expires, setExpires] = useState<Texpires>(30)
  const [autoPublish, setAutoPublish] = useState(true)
  const [jopago, setJopago] = useState(false)
  const [kleinanzeigen_onlyjobs, setKleinanzeigen_onlyjobs] = useState(false)
  const [freeBundle, setFreeBundle] = useState(false)
  const [rekruter, setRekruter] = useState(false)
  const [onlyjobs, setOnlyjobs] = useState(false)
  const [indeed_campaign, setIndeed_campaign] = useState(false)
  const [ebay_kleinanzeigen, setEbay_kleinanzeigen] = useState(false)
  const [xing, setXing] = useState(false)

  const [extraInputs, setExtraInputs] = useState('')
  const [extrasDefault, setExtrasDefault] = useState('')
  const [freeBundleJobBoards, setFreeBundleJobBoards] = useState<TfreeBundleJobBoards>()

  useEffect(() => {
    !freeBundle && setFreeBundleJobBoards(undefined)
  }, [freeBundle, freeBundleJobBoards])

  useEffect(() => {
    setExtrasDefault(JSON.stringify(extraInputs, null, 2))
  }, [extraInputs])

  useEffect(() => {
    if (defaultPublisher === 'schalttool' && publisher === 'schalttool') {
      // required values
      setClientId(publisherConfig.clientId)
      publisherConfig.expires && setExpires(publisherConfig.expires[0])
      setAutoPublish(publisherConfig.autopublish)
      // static 'context' values
      setJopago(publisherConfig.context.apis.jopago)
      setKleinanzeigen_onlyjobs(publisherConfig.context.apis.kleinanzeigen_onlyjobs)
      setFreeBundle(publisherConfig.context.apis.free_bundle)
      setRekruter(publisherConfig.context.apis.rekruter)
      setOnlyjobs(publisherConfig.context.apis.onlyjobs)
      setIndeed_campaign(publisherConfig.context.apis.indeed_campaign)
      setEbay_kleinanzeigen(publisherConfig.context.apis.ebay_kleinanzeigen)
      setXing(publisherConfig.context.apis.xing)
      // extras
      setExtraInputs(publisherConfig.extras)
    }
  }, [defaultPublisher, publisherConfig, publisher])

  useEffect(() => {
    const newSchalttoolConfig: TschalttoolConfig = {
      clientId,
      autopublish: autoPublish,
      expires: [expires, 'days'],
      context: {
        apis: {
          ebay_kleinanzeigen,
          xing,
          jopago,
          kleinanzeigen_onlyjobs,
          rekruter,
          onlyjobs,
          indeed_campaign,
          free_bundle: freeBundle,
        },
      },
      extras: extraInputs,
    }
    Object.assign(newSchalttoolConfig.context, { free_bundle: { apis: freeBundleJobBoards } })
    dispatch(setSchalttoolConfig(newSchalttoolConfig))
  }, [
    dispatch,
    clientId,
    expires,
    autoPublish,
    jopago,
    freeBundle,
    rekruter,
    extraInputs,
    freeBundleJobBoards,
    onlyjobs,
    indeed_campaign,
    kleinanzeigen_onlyjobs,
    ebay_kleinanzeigen,
    xing,
  ])

  return {
    dispatch,
    uniqueId,
    clientId,
    setClientId,
    expires,
    setExpires,
    autoPublish,
    setAutoPublish,
    jopago,
    setJopago,
    freeBundle,
    setFreeBundle,
    rekruter,
    setRekruter,
    extraInputs,
    setExtraInputs,
    extrasDefault,
    setFreeBundleJobBoards,
    onlyjobs,
    setOnlyjobs,
    indeed_campaign,
    setIndeed_campaign,
    kleinanzeigen_onlyjobs,
    setKleinanzeigen_onlyjobs,
    ebay_kleinanzeigen,
    setEbay_kleinanzeigen,
    xing,
    setXing,
  }
}

export default useAllHooks
