import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { Pie } from 'react-chartjs-2'

import LoadingSpinner from '../../components/atoms/loadingSpinner/LoadingSpinner'

ChartJS.register(ArcElement, Tooltip, Legend)

export const ChartTotalPostings: React.FC<IChartTotalPostings> = ({ feedStatistics }) => {
  const data = {
    labels: ['prepared', 'published', 'unpublished'],
    datasets: [
      {
        label: 'Job postings',
        data: [
          feedStatistics?.allPreparedPostings,
          feedStatistics?.allPublishedPostings,
          feedStatistics?.allUnpublishedPostings,
        ],
        backgroundColor: [
          'rgba(141, 221, 208, .6)',
          'rgba(144, 238, 144, .6)',
          'rgba(100, 100, 110, .6)',
        ],
        borderColor: ['rgba(141, 221, 208, 1)', 'rgba(144, 238, 144, 1)', 'rgba(100, 100, 110, 1)'],
        borderWidth: 1,
      },
    ],
  }

  if (!feedStatistics) return <LoadingSpinner />

  return (
    <div className='ChartTotalPostings'>
      <Pie data={data} />
    </div>
  )
}

interface IChartTotalPostings {
  feedStatistics: {
    allPreparedPostings: string
    allPublishedPostings: string
    allUnpublishedPostings: string
  }
}
