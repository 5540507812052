import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useGetFeedsListQuery } from '../../api/api'
import LoadingSpinner from '../../components/atoms/loadingSpinner/LoadingSpinner'
import { TfeedListItem } from '../../types/Tfeeds'
import SingleFeed from './singleFeed/SingleFeed'

const FeedsList: React.FC = () => {
  const [feeds, setFeeds] = useState<TfeedListItem[] | never[]>([])

  const { data: feedsList, isError, isLoading, isSuccess } = useGetFeedsListQuery()

  useEffect(() => {
    isSuccess && setFeeds(feedsList)
  }, [feedsList, isSuccess])

  isError && <>Error: fetch import run list list from server</>

  return (
    <div className='FeedsList pageContainer'>
      <div className='buttonsContainer'>
        {localStorage.userRole !== 'view' && (
          <Link to='/addnewfeed' className='AddNewFeed'>
            <button className='buttonBase'>Add new +</button>
          </Link>
        )}
      </div>
      <div className='table'>
        <div className='tableHeader'>
          <ul>
            <li>Status</li>
            <li>Name</li>
            <li>Reader</li>
            <li>Publisher</li>
            <li>Last import run</li>
            <li>Edit</li>
            <li>Options</li>
          </ul>
        </div>
        {isLoading && <LoadingSpinner />}
        {!!feeds?.length &&
          feeds.map((feed: TfeedListItem) => (
            <SingleFeed
              key={feed.id}
              id={feed.id}
              name={feed.name}
              publisher={feed.publisher}
              reader={feed.reader}
              url={feed.url}
              importRun={feed.importRuns}
              isActive={feed.isActive}
            />
          ))}
      </div>
    </div>
  )
}

export default FeedsList
