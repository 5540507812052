import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { TairtableConfig, Tparser } from '../../../../../types/Tfeeds'
import { TmappingConfigs } from '../../../../../types/Tmappings'
import { setAirtableConfig } from '../../reduxStates'

const useAllHooks = (publisher: string, mappingsConfigs: TmappingConfigs) => {
  const dispatch = useDispatch()
  const uniqueId = '239248'

  const [unpublishMethod, setUnpublishMethod] = useState<string>('delete record')
  const [mappingParser, setMappingParser] = useState<Tparser>('xml')

  useEffect(() => {
    if (publisher === 'airtable') {
      const newAirtableConfig: TairtableConfig = {
        unpublishMethod,
        mappings: mappingsConfigs,
        mappingParser,
      }
      dispatch(setAirtableConfig(newAirtableConfig))
    }
  }, [dispatch, publisher, unpublishMethod, mappingsConfigs, mappingParser])

  return {
    uniqueId,
    unpublishMethod,
    setUnpublishMethod,
    mappingParser,
    setMappingParser,
  }
}

export default useAllHooks
