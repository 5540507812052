import ObjectInput from '../../../../../components/organisms/objectInput/ObjectInput'
import useAllHooks from './hooksAndStates'

interface Iprops {
  publisher: string
}
export const Other: React.FC<Iprops> = ({ publisher }) => {
  const { setConfigs } = useAllHooks(publisher)
  if (publisher !== 'jass' && publisher !== 'schalttool') {
    return (
      <div className='Other publisherSelected'>
        <ObjectInput defaultValue={'{}'} onChange={setConfigs} uniqueId={'98764'} required />
      </div>
    )
  } else {
    return null
  }
}
