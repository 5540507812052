import { useState } from 'react'

import { DividerLine } from '../../../../../components/atoms/lines/DividerLine'
import SelectBox from '../../../../../components/organisms/selectBox/SelectBox'
import { IpropsPublisher } from '../../../../../types/Tfeeds'
import { TmappingConfigs } from '../../../../../types/Tmappings'
import { MappingFields } from '../../../sharedComponents/mappingFields/MappingFields'
import useAllHooks from './hooksAndStates'

const unpublishMethods = ['delete record', 'set status as unpublish']
const mappingParserList = ['json', 'xml']

export const Airtable: React.FC<IpropsPublisher> = ({
  publisherConfig,
  defaultPublisher,
  publisher,
  htmlSource,
}) => {
  const [mappingsConfigs, setMappingsConfigs] = useState<TmappingConfigs>()

  const { uniqueId, setUnpublishMethod, setMappingParser } = useAllHooks(
    publisherConfig,
    defaultPublisher,
    publisher,
    mappingsConfigs,
  )

  if (publisher === 'airtable') {
    return (
      <div className='Other publisherSelected'>
        <DividerLine />
        <SelectBox
          label='Unpublish method'
          onChange={setUnpublishMethod}
          uniqueId={uniqueId}
          selectList={unpublishMethods}
          defaultValue={publisherConfig.unpublishMethod}
        />
        <SelectBox
          label='Mapping parser'
          onChange={setMappingParser}
          uniqueId={uniqueId}
          selectList={mappingParserList}
          defaultValue={publisherConfig.mappingParser}
        />
        <DividerLine />
        <MappingFields
          htmlSource={htmlSource}
          setConfigs={setMappingsConfigs}
          initialValues={publisherConfig.mappings}
        />
      </div>
    )
  } else {
    return null
  }
}
