import { TzipSource } from '../../../../types/Tfeeds'

interface Iprops {
  onChange: (e: TzipSource) => void
  defaultValue?: TzipSource
}

const ZipSourceSelectBox: React.FC<Iprops> = ({ onChange, defaultValue }) => {
  const unique = 'ZipSourceSelectBox-51984'
  const sources = ['create', 'url']

  return (
    <div className={'SelectBox TemplateSelectBox'}>
      <label htmlFor={unique}>Zip source</label>
      <select
        value={defaultValue}
        onChange={(e) => onChange(e.target.value as TzipSource)}
        id={unique}
        required={true}
      >
        {sources.map((i: string) => (
          <option value={i} key={i}>
            {i}
          </option>
        ))}
      </select>
    </div>
  )
}

export default ZipSourceSelectBox
