import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { TairtableConfig, Tparser, TpublisherConfig } from '../../../../../types/Tfeeds'
import { TmappingConfigs } from '../../../../../types/Tmappings'
import { setAirtableConfig } from '../../reduxStates'

const useAllHooks = (
  publisherConfig: TpublisherConfig,
  defaultPublisher: string,
  publisher: string,
  mappingsConfigs: TmappingConfigs,
) => {
  const dispatch = useDispatch()
  const uniqueId = '87878'

  const [unpublishMethod, setUnpublishMethod] = useState<string>('')
  const [mappingParser, setMappingParser] = useState<Tparser>('xml')

  useEffect(() => {
    if (defaultPublisher === 'airtable' && publisher === 'airtable') {
      // required values
      setUnpublishMethod(publisherConfig.unpublishMethod)
      setMappingParser(publisherConfig.mappingParser)
      // extras
    }
  }, [defaultPublisher, publisherConfig, publisher])

  useEffect(() => {
    const newAirtableConfig: TairtableConfig = {
      unpublishMethod,
      mappings: mappingsConfigs,
      mappingParser,
    }
    dispatch(setAirtableConfig(newAirtableConfig))
  }, [dispatch, unpublishMethod, mappingsConfigs, mappingParser])

  return {
    uniqueId,
    setUnpublishMethod,
    mappingParser,
    setMappingParser,
  }
}

export default useAllHooks
