import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Tauthorization } from '../../../../types/Tfeeds'
import { setAuthorizationConfigs } from '../reduxStates'

const useAllHooks = (
  authorization: Tauthorization,
  setAuthorization: (e: Tauthorization) => void,
  initialValues: any,
) => {
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [pageLoaded, setPageLoaded] = useState(true)

  const uniqueId = '67988'
  const dispatch = useDispatch()

  useEffect(() => {
    const username = initialValues?.authorizationConfigs?.username
    const password = initialValues?.authorizationConfigs?.password

    if (username || password) {
      setUserName(username)
      setPassword(password)
      dispatch(setAuthorizationConfigs({ username, password }))
    }
  }, [dispatch, initialValues])

  useEffect(() => {
    if (pageLoaded) {
      setAuthorization(initialValues.authorization)

      if (authorization !== 'basic' && authorization !== 'none')
        throw new Error('authorization method not supported')
    }
    setPageLoaded(false)
  }, [username, password, authorization, setAuthorization, initialValues.authorization, pageLoaded])

  useEffect(() => {
    if ((username || password) && authorization === 'basic') {
      const authConfigs = { username, password }
      dispatch(setAuthorizationConfigs(authConfigs))
    }
  }, [password, username, dispatch, authorization])

  return {
    uniqueId,

    username,
    setUserName,
    password,
    setPassword,
  }
}

export default useAllHooks
