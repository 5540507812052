import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { useDeleteUserMutation, useUpdateUserMutation } from '../../api/api'
import SelectBox from '../../components/organisms/selectBox/SelectBox'
import TextInput from '../../components/organisms/textInput/TextInput'
import { TresponseError } from '../../types/Tgeneral'
import { userRoleList } from './Users'
import { isEmailValid } from './utils'

const uniqueId = '88383'

const notify = (message: string, autoClose = 3000) => toast(message, { autoClose })

export const EditUser: React.FC = () => {
  let { username: oldUsername, role: oldRole } = useParams()
  const { id } = useParams()
  if (!oldUsername) oldUsername = ''
  if (!oldRole) oldRole = ''

  const [
    updateUser,
    { isLoading: isLoadingUpdateUser, isSuccess: isSuccessUpdateUser, error: errorUpdateUser },
  ] = useUpdateUserMutation()
  const [
    deleteUser,
    { isLoading: isLoadingDelete, error: errorDelete, isSuccess: isSuccessDelete },
  ] = useDeleteUserMutation()

  const [username, setUsername] = useState(oldUsername)
  const [role, setRole] = useState(oldRole)

  useEffect(() => {
    if (errorUpdateUser) {
      const errorObject: TresponseError = { ...errorUpdateUser } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }
    isSuccessUpdateUser && notify('Updated successfully!')
    isLoadingUpdateUser && notify('Updating...', 700)
  }, [errorUpdateUser, isSuccessUpdateUser, isLoadingUpdateUser])

  useEffect(() => {
    if (errorDelete) {
      const errorObject: TresponseError = { ...errorDelete } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }
    isSuccessDelete && notify('Deleted successfully!')
    isLoadingDelete && notify('Deleting...', 700)
  }, [errorDelete, isSuccessDelete, isLoadingDelete])

  return (
    <div className='EditUser pageContainer'>
      <div className='breadcrumbs'>
        <span>
          <Link to='/'>
            <p>home</p>
          </Link>
        </span>
        <span> / </span>
        <span>
          <Link to='/users'>
            <p>users</p>
          </Link>
        </span>
        <span> / </span>
        <span>{id}</span>
      </div>
      <div className='greyRoundContainer'>
        <form onSubmit={handleSave}>
          <h1>Edit user with ID: {id}</h1>
          <div className='inputContainer'>
            <TextInput
              label='Username'
              value={username}
              onChange={(e) => setUsername(e.currentTarget.value)}
              uniqueId={uniqueId}
            />
            <SelectBox
              label='Role'
              onChange={setRole}
              uniqueId={uniqueId}
              defaultValue={role}
              selectList={userRoleList}
            />
          </div>
          <div className='buttons'>
            <button className='SaveButt buttonBase' type='submit'>
              Update
            </button>
          </div>
        </form>
      </div>
      <div className='greyRoundContainer deleteUser'>
        <form onSubmit={handleDelete}>
          <h1>Delete user</h1>
          <button className='buttonBase deleteUserButton'>Delete user</button>
        </form>
      </div>
      <ToastContainer limit={3} position='top-center' />
    </div>
  )

  async function handleSave(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const newUserData = { username, role }
    if (!isEmailValid(username)) {
      notify('Email not valid')
      return
    }
    await updateUser({ id, newUserData })
  }
  async function handleDelete(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    await deleteUser(id)
  }
}
