export const fullXml = `<?xml version="1.0" encoding="utf-8"?>
<jobs>
  <job>
    <apply_email><![CDATA[svenja.kruessel@zvoove.com]]></apply_email>
    <apply_url
      ><![CDATA[https://jobs.europersonal.com/karriere/jobs/bewerbung/46cc41ad-641b-4e79-b57e-19ffc78780f6]]></apply_url
    >
    <company_id><![CDATA[]]></company_id>
    <company><![CDATA[zvoove Group GmbH]]></company>
    <created>2022-11-05T00:00:00+0000</created>
    <description
      ><![CDATA[ <!-- templateType: page isAvailableForNewContent: true --> <!doctype html> <html>
      <body> <!-- Header START --> <header class="hero hero-diago"> <a href="https://www.zvoove.com"
      target="_blank"><img
      src="https://f.hubspotusercontent10.net/hubfs/8673311/Corportate%20Identity/2020-10%20zvoove%20Logo%20light.svg"
      class="logo" alt=""></a> <div class="circle"></div> <div class="container px-4">
      <h1>Ausbildung zum Kaufmann f&#252;r IT-Systemmanagement (m/w/d)</h1> <ul class="list-inline">
      <li class="list-inline-item"><span class="material-icons">room</span> Wietmarschen-Lohne</li>
      <li class="list-inline-item"><span class="material-icons color-primary">business_center</span>
      Vollzeit</li> </ul> </div> </header> <!-- Header END --> <!-- Info START --> <div
      class="container"> <div class="row"> <div class="col-xl-8 mt-custom"> <p class="text-right"><a
      class="alljobs-link disabledForPreview"
      href="https://jobs.europersonal.com/karriere/jobs"><span
      class="material-icons">navigate_before</span> Zur Stellenübersicht</a></p> <div class="card
      main-content-card"> <ul class="list-inline pt-5"> <li class="list-inline-item"> <a
      href="https://jobs.europersonal.com/karriere/jobs/bewerbung/46cc41ad-641b-4e79-b57e-19ffc78780f6"
      class="btn btn-primary disabledForPreview mb-2">Jetzt bewerben</a> </li> <li
      class="list-inline-item"> <div class="social-icons"> <a
      href="https://facebook.com/sharer/sharer.php?u=https%3a%2f%2fjobs.europersonal.com%2fstelle%2fausbildung-zum-kaufmann-fuer-it-systemmanagement-m-w-d-49835-wietmarschen-lohne-46cc41ad641b4e79b57e19ffc78780f6%3forigin%3djobs"
      target="_blank"><span>Facebook</span></a> <a
      href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3a%2f%2fjobs.europersonal.com%2fstelle%2fausbildung-zum-kaufmann-fuer-it-systemmanagement-m-w-d-49835-wietmarschen-lohne-46cc41ad641b4e79b57e19ffc78780f6%3forigin%3djobs&amp;title=Ausbildung%20zum%20Kaufmann%20f%c3%bcr%20IT-Systemmanagement%20(m%2fw%2fd)&amp;summary=Ausbildung%20zum%20Kaufmann%20f%c3%bcr%20IT-Systemmanagement%20(m%2fw%2fd)&amp;source=https%3a%2f%2fjobs.europersonal.com%2fstelle%2fausbildung-zum-kaufmann-fuer-it-systemmanagement-m-w-d-49835-wietmarschen-lohne-46cc41ad641b4e79b57e19ffc78780f6%3forigin%3djobs"
      target="_blank"><span>LinkedIn</span></a> <a
      href="https://twitter.com/intent/tweet/?url=https%3a%2f%2fjobs.europersonal.com%2fstelle%2fausbildung-zum-kaufmann-fuer-it-systemmanagement-m-w-d-49835-wietmarschen-lohne-46cc41ad641b4e79b57e19ffc78780f6%3forigin%3djobs"
      target="_blank"><span>LinkedIn</span></a> <a
      href="https://www.xing.com/app/user?op=share;url=https%3a%2f%2fjobs.europersonal.com%2fstelle%2fausbildung-zum-kaufmann-fuer-it-systemmanagement-m-w-d-49835-wietmarschen-lohne-46cc41ad641b4e79b57e19ffc78780f6%3forigin%3djobs;title=Ausbildung%20zum%20Kaufmann%20f%c3%bcr%20IT-Systemmanagement%20(m%2fw%2fd)"
      target="_blank"><span>Xing</span></a> </div> </li> </ul> <div class="card-body"> </div> <div
      itemprop="responsibilities" class="job-text-section"> <div class="text-light"><p>zvoove
      entwickelt und vertreibt innovative Softwarel&ouml;sungen f&uuml;r die Personal-, die
      Geb&auml;ude- und die Eventdienstleistung. Wir setzen uns die ganzheitliche Digitalisierung
      von Gesch&auml;ftsprozessen zum Ziel. So bieten wir spannende Perspektiven f&uuml;r rund 350
      Mitarbeiter an 7&nbsp;Standorten in Deutschland, der Schweiz und den Niederlanden:
      #wearezvoover. In zvoove flie&szlig;t die Erfahrung von mehr als 100 Jahren
      Software-Entwicklung und -Vertrieb: Die Unternehmensgruppe entstand aus dem Zusammenschluss
      von LANDWEHR, rhb, prosoft, BackOffice und Pivoton.<br> <br> Zur Verst&auml;rkung unseres
      Teams suchen wir Auszubildende f&uuml;r den Bereich IT-System-Management (m/w/d).<br>
      &nbsp;<br> <strong>Deine Aufgaben</strong></p> <ul> <li>Du betreust unsere Kunden im
      Hotlineservice</li> <li>Du sorgst f&uuml;r die Qualit&auml;tssicherung unserer Software</li>
      <li>Du installierst die Software bei unseren Kunden vor Ort</li> <li>Du k&uuml;mmerst Dich um
      die Softwareinstallation per Fernwartung</li> <li>Du aktualisierst unsere
      Schulungsunterlagen</li> <li>Du erstellst unsere Handb&uuml;cher</li> </ul> <p><strong>Dein
      Profil</strong></p> <ul> <li>Technisches Verst&auml;ndnis</li> <li>Spa&szlig; am Beraten</li>
      <li>Kommunikationsst&auml;rke und Geduld</li> <li>Analytisches Denkverm&ouml;gen</li>
      <li>Konzentrierte und verantwortungsvolle Arbeitsweise</li> <li>Selbstst&auml;ndigkeit und
      Teamf&auml;higkeit</li> </ul> <p><strong>Deine Vorteile als zvoover</strong></p> <ul> <li>Eine
      interessante und abwechslungsreiche Ausbildung in einem modernen Softwareunternehmen</li>
      <li>Volle Unterst&uuml;tzung bei der Pr&uuml;fungsvorbereitung&nbsp;</li> <li>Chance auf
      &Uuml;bernahme nach der Ausbildung</li> <li>Attraktives Gehalt</li> <li>Sozialleistungen</li>
      <li>Schulungs- und Weiterbildungsm&ouml;glichkeiten</li> <li>Gesundheitsf&ouml;rderung, z. B.
      gro&szlig;er Fitnessraum zur freieen Verf&uuml;gung (24/7)</li> <li>M&ouml;glichkeit zur
      Teilnahme an qualitrain&nbsp;</li> <li>Bistro</li> </ul> </div> </div> </div> </div> </div>
      </div> <!-- Info END --> <!-- Benefits START --> <div class="container"> <div class="row"
      id="benefits"> <!-- 1 --> <div class="col-lg-6 col-xl-3"> <div class="card"> <div
      class="card-body"> <span class="material-icons color-yellow">favorite</span>
      <p><strong>Attraktive Sozialleistungen</strong></p> <p>Überdurchschnittliche Sozialleistungen
      sind genauso selbstverständlich wie ein freundschaftliches Miteinander.</p> </div> </div>
      </div> <!-- 2 --> <div class="col-lg-6 col-xl-3"> <div class="card"> <div class="card-body">
      <span class="material-icons color-purple">favorite</span> <p><strong>Möglichkeit zum mobilen
      Arbeiten</strong></p> <p>Neben unseren 5 attraktiven Standorten, an denen dein neuer
      Arbeitsplatz sein kann, bieten wir auch mobiles Arbeiten an.</p> </div> </div> </div> <!-- 3
      --> <div class="col-lg-6 col-xl-3"> <div class="card"> <div class="card-body"> <span
      class="material-icons color-green">favorite</span> <p><strong>30 Tage Urlaub</strong></p>
      <p>Work-Life-Balance ist uns wichtig: Dafür gibt's z. B. 30 Urlaubstage pro
      Jahr.<br>&nbsp;</p> </div> </div> </div> <!-- 4 --> <div class="col-lg-6 col-xl-3"> <div
      class="card"> <div class="card-body"> <span class="material-icons color-red">favorite</span>
      <p><strong>Kostenfreie Snacks &amp; Getränke</strong></p> <p>Profitiere von Getränken aller
      Art, Snacks für die süßen Momente und vielem mehr.<br>&nbsp;</p> </div> </div> </div> </div>
      </div> <!-- Product Info END --> <div class="container"> <div class="col-lg-8"> <div
      class="contact-info"> <div class="row"> <div class="col-xl-5 order-last order-lg-first
      img-bg"> <img
      src="https://jobs.europersonal.com/api/v1/mandantoeffentlichedatei/GetAsStreamContent?uuid=b686a19a-d91d-41e3-b2cd-c82da67bc031"
      alt="" loading="lazy"> </div> <div class="col-xl-7 order-first order-lg-last"
      style="background-color: #343f57;"> <div itemprop="contact"> <h2 class="pt-4"
      data-type="section-title">Ich freue mich auf Deine Bewerbung</h2> <address> <p>Frau&nbsp;<br>
      Svenja Kr&uuml;&szlig;el<br> D-49835 Wietmarschen-Lohne<br> Tel.: +49 5908 938-3750<br>
      E-Mail: career@zvoove.com</p> </address> </div> <ul class="list-inline pt-5"> <li
      class="list-inline-item"> <a
      href="https://jobs.europersonal.com/karriere/jobs/bewerbung/46cc41ad-641b-4e79-b57e-19ffc78780f6"
      class="btn btn-primary disabledForPreview">Jetzt bewerben</a> </li> <li
      class="list-inline-item"> <div class="social-icons2"> <a
      href="https://facebook.com/sharer/sharer.php?u=https%3a%2f%2fjobs.europersonal.com%2fstelle%2fausbildung-zum-kaufmann-fuer-it-systemmanagement-m-w-d-49835-wietmarschen-lohne-46cc41ad641b4e79b57e19ffc78780f6%3forigin%3djobs"
      target="_blank"><span>Facebook</span></a> <a
      href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3a%2f%2fjobs.europersonal.com%2fstelle%2fausbildung-zum-kaufmann-fuer-it-systemmanagement-m-w-d-49835-wietmarschen-lohne-46cc41ad641b4e79b57e19ffc78780f6%3forigin%3djobs&amp;title=Ausbildung%20zum%20Kaufmann%20f%c3%bcr%20IT-Systemmanagement%20(m%2fw%2fd)&amp;summary=Ausbildung%20zum%20Kaufmann%20f%c3%bcr%20IT-Systemmanagement%20(m%2fw%2fd)&amp;source=https%3a%2f%2fjobs.europersonal.com%2fstelle%2fausbildung-zum-kaufmann-fuer-it-systemmanagement-m-w-d-49835-wietmarschen-lohne-46cc41ad641b4e79b57e19ffc78780f6%3forigin%3djobs"
      target="_blank"><span>LinkedIn</span></a> <a
      href="https://twitter.com/intent/tweet/?url=https%3a%2f%2fjobs.europersonal.com%2fstelle%2fausbildung-zum-kaufmann-fuer-it-systemmanagement-m-w-d-49835-wietmarschen-lohne-46cc41ad641b4e79b57e19ffc78780f6%3forigin%3djobs"
      target="_blank"><span>LinkedIn</span></a> <a
      href="https://www.xing.com/app/user?op=share;url=https%3a%2f%2fjobs.europersonal.com%2fstelle%2fausbildung-zum-kaufmann-fuer-it-systemmanagement-m-w-d-49835-wietmarschen-lohne-46cc41ad641b4e79b57e19ffc78780f6%3forigin%3djobs;title=Ausbildung%20zum%20Kaufmann%20f%c3%bcr%20IT-Systemmanagement%20(m%2fw%2fd)"
      target="_blank"><span>Xing</span></a> </div> </li> </ul> </div> </div> </div> </div> </div>
      </body> </html>]]></description
    >
    <field_of_work><![CDATA[]]></field_of_work>
    <id>f2ssfs33fssdf</id>
    <productIds><productId>123</productId><productId>123456</productId></productIds>
    <industry><![CDATA[]]></industry>
    <location>
      <city><![CDATA[aWietmarschen-Lohne]]></city>
      <country><![CDATA[DE]]></country>
      <zip><![CDATA[49836]]></zip>
    </location>
    <reference_number><![CDATA[46cc41ad641b4e79b57e19ffc78780f6_18]]></reference_number>
    <titel><![CDATA[ekddAusbildung zum Kaufmann für IT-Systemmanagement (m/w/d)]]></titel>
    <url
      ><![CDATA[https://jobs.europersonal.com/stelle/ausbildung-zum-kaufmann-fuer-it-systemmanagement-m-w-d-49835-wietmarschen-lohne-46cc41ad641b4e79b57e19ffc78780f6?origin=jobs]]></url
    >
  </job>
  <job> ... </job>
</jobs>
`
export const jobsContext = `<?xml version="1.0" encoding="utf-8"?>
<jobs>
  <job>
  ...
  </job>
  <job>
   ... 
  </job>
</jobs>
`
export const directJobs = `
  <job>
  ...
  </job>
  <job>
   ... 
  </job>
`
export const jobFields = `
<jobs>
  <job>
    <id>123</id>
  </job>
</jobs>
`
export const locations = `
<jobs>
  <job>
    <id>123</id>
    <locations>
      <location>
        <city><![CDATA[aWietmarschen-Lohne]]></city>
        <country><![CDATA[DE]]></country>
        <zip><![CDATA[49836]]></zip>
      </location>
      <location>
        <city><![CDATA[aWietmarschen-Lohne]]></city>
        <country><![CDATA[DE]]></country>
        <zip><![CDATA[49836]]></zip>
      </location>
    </locations>
  </job>
</jobs>
`
export const jobsContextSample = {
  stellen: [
    {
      job1: {
        handle: '...',
        title: '...',
      },
    },
    {
      job2: {
        handle: '...',
        title: '...',
      },
    },
  ],
}

export const jobsContextDirectArraySample = [
  {
    job1: {
      handle: '...',
      title: '...',
    },
  },
  {
    job2: {
      handle: '...',
      title: '...',
    },
  },
]

export const jsonLocationsSample = {
  stellen: [
    {
      job1: {
        handle: '...',
        title: '...',
        allLocations: [
          { zipCode: '', city: '' },
          { zipCode: '', city: '' },
        ],
      },
    },
  ],
}
