import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  useSingleFeedJobPostingsStatsCurrentMonthQuery,
  useSingleFeedJobPostingsStatsQuery,
} from '../../api/api'
import LoadingSpinner from '../../components/atoms/loadingSpinner/LoadingSpinner'

const chartWidth = 1000
const currentMonth = new Date().toLocaleString('default', { month: 'long' })

export const ChartPostings: React.FC<IchartPostings> = ({ feedId }) => {
  const { data, isLoading } = useSingleFeedJobPostingsStatsQuery(feedId)
  const { data: dataQuotaUsage, isLoading: isLoadingCurrentMonth } =
    useSingleFeedJobPostingsStatsCurrentMonthQuery(feedId)

  if (isLoading) return <LoadingSpinner />

  return (
    <div className='postingsChartContainer'>
      <h2 className='lineChartHead'>Last 10 Import Runs</h2>
      <BarChart width={chartWidth} height={400} data={data?.lastImportRuns}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='startTime' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='planned' stackId='a' fill='gray' />
        <Bar dataKey='processed' stackId='a' fill='green' />
        <Bar dataKey='error' stackId='a' fill='red' />
      </BarChart>

      <h2 className='lineChartHead'>Job Postings in {currentMonth}</h2>
      <AreaChart
        width={chartWidth}
        height={400}
        data={data?.countedByState}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='date' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area
          type='monotone'
          dataKey='prepared'
          stackId='1'
          stroke='rgba(141, 221, 208, 1)'
          fill='rgba(141, 221, 208, .6)'
        />
        <Area
          type='monotone'
          dataKey='published'
          stackId='1'
          stroke='rgba(144, 238, 144, 1)'
          fill='rgba(144, 238, 144, .6)'
        />
        <Area
          type='monotone'
          dataKey='unpublished'
          stackId='1'
          stroke='rgba(100, 100, 110, 1)'
          fill='rgba(100, 100, 110, .6)'
        />
      </AreaChart>

      <h2 className='lineChartHead'>Quota usage in {currentMonth}</h2>
      {!isLoadingCurrentMonth && (
        <ComposedChart
          width={chartWidth}
          height={500}
          data={dataQuotaUsage.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='date' />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine
            y={dataQuotaUsage.monthlyHardQuota}
            label='hard quota'
            stroke='red'
            strokeDasharray='15 10'
            alwaysShow
          />
          <ReferenceLine
            y={dataQuotaUsage.monthlySoftQuota}
            label='soft quota'
            stroke='#FFC300'
            strokeDasharray='7 5'
            alwaysShow
          />
          <Area
            type='monotone'
            dataKey='cumulatedPublished'
            name='published (cumulated total)'
            stackId='1'
            stroke='rgba(144, 238, 144, .8)'
            fill='rgba(144, 238, 144, .4)'
          />
        </ComposedChart>
      )}
    </div>
  )
}

interface IchartPostings {
  feedId: string | undefined
}
