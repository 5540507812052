import { useDispatch } from 'react-redux'

import { setIsUserLoggedIn } from '../../redux/slices/user'

const Dashboard: React.FC = () => {
  const dispatch = useDispatch()

  function logOut(event: { preventDefault: () => void }) {
    event.preventDefault()
    dispatch(setIsUserLoggedIn(false))
    localStorage.removeItem('userId')
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('role')
  }

  return (
    <div className='Dashboard'>
      <div className='pageContainer'>
        <div className='panel'>
          <button className='buttonBase logout' onClick={logOut}>
            Log out
          </button>
          <div className='userInfos'>
            <p>user Id: {localStorage['userId']}</p>
            <p>username: {localStorage['username']}</p>
            <p>role: {localStorage['userRole']}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
