import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import TextInput from '../../../../components/organisms/textInput/TextInput'
import { ThtmlSource, TreaderConfig } from '../../../../types/Tfeeds'
import { TmappingConfigs } from '../../../../types/Tmappings'
import { TreaderConfigGenericJson } from '../../../../types/readerConfig'
import { MappingFields } from '../../sharedComponents/mappingFields/MappingFields'
import { setReaderConfig } from '../reduxStates'

interface Iprops {
  initialValues: TreaderConfigGenericJson
  htmlSource: ThtmlSource
}
const GenericJson: React.FC<Iprops> = ({ initialValues, htmlSource }) => {
  const [jobsContext, setJobsContext] = useState('jobs')
  const [mappingConfigs, setMappingConfigs] = useState<TmappingConfigs>()
  const uniqueId = '67988'

  const dispatch = useDispatch()

  useEffect(() => {
    if (initialValues) setJobsContext(initialValues.jobsContext)
  }, [initialValues])

  useEffect(() => {
    if (mappingConfigs) {
      const readerConfig: TreaderConfig = { jobsContext, ...mappingConfigs }
      dispatch(setReaderConfig(readerConfig))
    }
  }, [dispatch, jobsContext, mappingConfigs])

  return (
    <div className='GenericJson'>
      <div className='title'>
        <h2>JSON mappings</h2>
        <Link target='_blank' to='/mappinghelperjson' className='help'>
          <h3>Help</h3>
        </Link>
      </div>

      <div className='jobList'>
        <TextInput
          label='Job list context'
          uniqueId={uniqueId}
          onChange={(e) => setJobsContext(e.currentTarget.value)}
          value={jobsContext}
          required={false}
        />
        <p>
          Choose the context path which the list of jobs is located in response data. If the
          response gives directy the list of jobs, please fill this field as empty (no dots are
          needed)
        </p>
      </div>
      <MappingFields
        htmlSource={htmlSource}
        setConfigs={setMappingConfigs}
        initialValues={initialValues}
      />
    </div>
  )
}

export default GenericJson
