import { useState } from 'react'

import './style.scss'

export const ErrorField: React.FC<IerrorMsg> = ({ errorMessage }) => {
  const [show, setShow] = useState(false)
  const [clicked, setClicked] = useState(false)

  function clickAction() {
    navigator.clipboard.writeText(errorMessage)
    setClicked(true)
  }

  return (
    <div
      className='ErrorField copyToClipboard'
      onClick={clickAction}
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {errorMessage && !clicked && <span>Copy to clipboard </span>}
      {clicked && <span className='copied'>Copied!</span>}
      {show && <div className='errorOnHover'>{errorMessage}</div>}
    </div>
  )
}

export interface IerrorMsg {
  errorMessage: string
}
