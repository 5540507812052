import { ThtmlSource } from '../../../../types/Tfeeds'

interface Iprops {
  onChange: (e: ThtmlSource) => void
  defaultValue?: ThtmlSource
}

const HtmlSourceSelectBox: React.FC<Iprops> = ({ onChange, defaultValue }) => {
  const unique = 'HtmlSourceSelectBox-51984'
  const sources = ['url', 'template', 'embedded']

  return (
    <div className={'SelectBox TemplateSelectBox'}>
      <label htmlFor={unique}>Html source</label>
      <select
        value={defaultValue}
        onChange={(e) => onChange(e.target.value as ThtmlSource)}
        id={unique}
        required={true}
      >
        {sources.map((i: string) => (
          <option value={i} key={i}>
            {i}
          </option>
        ))}
      </select>
    </div>
  )
}

export default HtmlSourceSelectBox
