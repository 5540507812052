import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Link, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { useSingleFeedJobPostingsQuery } from '../../../../api/api'
import LoadingSpinner from '../../../../components/atoms/loadingSpinner/LoadingSpinner'
import { IjobPosting, SingleJobPosting } from './SingleJobPosting'

const JobPostings: React.FC = () => {
  const { feedId, feedName } = useParams()

  const itemsPerPage = 60
  const [records, setrecords] = useState(itemsPerPage)

  const [jobPostings, setJobPostings] = useState<[IjobPosting]>()
  const [hasMore, setHasMore] = useState(true)

  const {
    data: jobPostingsList,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useSingleFeedJobPostingsQuery(feedId)

  useEffect(() => {
    isSuccess && setJobPostings(jobPostingsList)
  }, [jobPostingsList, isSuccess])

  if (isError) {
    return <h1>{JSON.stringify(error, null, 2)}</h1>
  }
  if (isSuccess && !jobPostingsList?.length) {
    return <h1 className='emptyList'>No JobPostings for this feed</h1>
  }

  return (
    <div className='JobPostings pageContainerWide'>
      <div className='breadcrumbs'>
        <span>
          <Link to='/feeds'>
            <p>feeds</p>
          </Link>
        </span>
        <span> / </span>
        <Link to={`/editfeed/${feedId}`}>
          <p>
            {feedName} : {feedId}
          </p>
        </Link>
      </div>
      <h1>
        Job Postings for <span>{feedName}</span>
      </h1>
      <div className='table'>
        <ul className='tableHeader'>
          <li>Id</li>
          <li>Feed</li>
          <li>Title</li>
          <li>State</li>
          <li>Ext Export</li>
          <li>Ext Import</li>
          <li>ImportTime</li>
          <li>UpdateTime</li>
          <li>UnpublishTime</li>
          <li>Expiration Date</li>
        </ul>
        {isLoading && <LoadingSpinner />}
        {jobPostings && (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={<LoadingSpinner key={uuidv4()} />}
            useWindow={true}
          >
            <div className='tableContent'>{showItems(jobPostings, records)}</div>
          </InfiniteScroll>
        )}
      </div>
    </div>
  )

  function loadMore() {
    if (jobPostings && records >= jobPostings?.length) {
      setHasMore(false)
    } else {
      setTimeout(() => {
        setrecords(records + itemsPerPage)
      }, 200)
    }
  }
}

function showItems(runsList: IjobPosting[], records: number) {
  const items = []
  for (let i = 0; i < records; i++) {
    if (runsList[i]?.id) {
      items.push(
        <SingleJobPosting
          key={runsList[i].id}
          id={runsList[i].id}
          state={runsList[i].state}
          feedId={runsList[i].feedId}
          title={runsList[i].title}
          externalImportId={runsList[i].externalImportId}
          externalExportId={runsList[i].externalExportId}
          importTime={runsList[i].importTime}
          updateTime={runsList[i].updateTime}
          unpublishTime={runsList[i].unpublishTime}
          publishedUrl={runsList[i].publishedUrl}
          expirationDate={runsList[i].expirationDate}
        />,
      )
    }
  }
  return items
}

export default JobPostings
