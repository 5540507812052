import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Link, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { useSingleFeedJobPostingImportsQuery } from '../../../../api/api'
import { SearchIcon } from '../../../../components/atoms/icons/searchIcon/SearchIcon'
import { DividerLine } from '../../../../components/atoms/lines/DividerLine'
import LoadingSpinner from '../../../../components/atoms/loadingSpinner/LoadingSpinner'
import NumberInput from '../../../../components/organisms/numberInput/NumberInput'
import SelectBox from '../../../../components/organisms/selectBox/SelectBox'
import TextInput from '../../../../components/organisms/textInput/TextInput'
import { removeMiliSeconds } from '../../../../util/tools/tools'
import { IsingleJobPostingImport, SingleJobPostingImport } from './SingleJobPostingImport'

const statusList = ['all', 'planned', 'processed', 'error']
const actionList = ['all', 'publish', 'update', 'unpublish', 'unchanged']

const uniqueId = 'sd5f13'
const JobPostingImports: React.FC = () => {
  const { importRunId } = useParams()

  const itemsPerPage = 60
  const [records, setrecords] = useState(itemsPerPage)

  // filters
  const [title, setTitle] = useState('')
  const [externalImportId, setExternalImportId] = useState('')
  const [status, setStatus] = useState('all')
  const [action, setAction] = useState('all')

  const [jobPostingImports, setJobPostingImports] = useState<[IsingleJobPostingImport]>()
  const [hasMore, setHasMore] = useState(true)

  const { data, isError, isLoading, isSuccess, error, refetch } =
    useSingleFeedJobPostingImportsQuery({
      importRunId,
      title,
      externalImportId,
      status,
      action,
    })

  useEffect(() => {
    isSuccess && setJobPostingImports(data?.jobPostings)
  }, [data?.jobPostings, isSuccess])

  if (isError) {
    return <h1>{JSON.stringify(error, null, 2)}</h1>
  }

  return (
    <div className='JobPostingImports pageContainerWide'>
      <div className='breadcrumbs'>
        <span>
          <Link to='/feeds'>
            <p>feeds</p>
          </Link>
        </span>
        <span> / </span>
        <Link to={`/editfeed/${data?.importRun?.feedId}`}>
          <span>
            <p>
              {data?.feedName} : {data?.importRun?.feedId}
            </p>
          </span>
        </Link>
        <span> / </span>
        <span>
          {removeMiliSeconds(data?.importRun?.startTime)} : {data?.importRun?.id}
        </span>
      </div>
      <h1>
        Job posting imports for import run id: <span>{importRunId}</span>
      </h1>
      <form onSubmit={refetch}>
        <DividerLine />
        <div className='filters'>
          <TextInput
            label='Title'
            onChange={(e) => setTitle(e.currentTarget.value)}
            value={title}
            uniqueId={uniqueId}
            required={false}
            styleType={'labelAbove'}
          />
          <NumberInput
            label='External import id'
            onChange={(e) => setExternalImportId(e.currentTarget.value)}
            value={externalImportId}
            uniqueId={uniqueId}
            required={false}
            styleType={'labelAbove'}
          />
          <SelectBox
            styleType='subFormSelectBox'
            label='Status'
            uniqueId={uniqueId}
            selectList={statusList}
            onChange={setStatus}
            defaultValue={status}
          />
          <SelectBox
            styleType='subFormSelectBox'
            label='Action'
            uniqueId={uniqueId}
            selectList={actionList}
            onChange={setAction}
            defaultValue={action}
          />
          <button></button>
          <button type='submit' className='SearchButton'>
            <SearchIcon />
          </button>
        </div>
      </form>
      <div className='table'>
        <div className='tableHeader'>
          <ul>
            <li>Id</li>
            <li>Feed</li>
            <li>ImportRun</li>
            <li>Ext Import</li>
            <li>action</li>
            <li>status</li>
            <li>jobPostingId</li>
            <li>title</li>
            <li>ErrorMessage</li>
          </ul>
        </div>
        {isLoading && <LoadingSpinner />}
        {jobPostingImports && (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={<LoadingSpinner key={uuidv4()} />}
            useWindow={true}
          >
            <div className='tableContent'>{showItems(jobPostingImports, records)}</div>
          </InfiniteScroll>
        )}
      </div>
    </div>
  )

  function loadMore() {
    if (jobPostingImports && records >= jobPostingImports?.length) {
      setHasMore(false)
    } else {
      setTimeout(() => {
        setrecords(records + itemsPerPage)
      }, 200)
    }
  }
}

function showItems(runsList: IsingleJobPostingImport[], records: number) {
  const items = []
  for (let i = 0; i < records; i++) {
    if (runsList[i]?.id) {
      items.push(
        <SingleJobPostingImport
          key={runsList[i].id}
          id={runsList[i].id}
          feedId={runsList[i].feedId}
          importRunId={runsList[i].importRunId}
          jobPostingId={runsList[i].jobPostingId}
          externalImportId={runsList[i].externalImportId}
          action={runsList[i].action}
          status={runsList[i].status}
          title={runsList[i].title}
          errorMessage={runsList[i].errorMessage}
        />,
      )
    }
  }
  return items
}

export default JobPostingImports
