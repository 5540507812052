import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { setDebugConfig } from '../../reduxStates'

const useAllHooks = (publisher: string) => {
  const dispatch = useDispatch()
  const uniqueId = '65555'

  const [configs, setConfigs] = useState<string>('')

  useEffect(() => {
    if (Object.values(configs).length && publisher === 'debug') {
      dispatch(setDebugConfig(configs))
    }
  }, [dispatch, configs, publisher])

  return { uniqueId, configs, setConfigs }
}

export default useAllHooks
