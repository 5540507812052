import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const token = ''

const authSlice = createSlice({
  name: 'auth',
  initialState: { token },
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
  },
})

export default authSlice.reducer
export const { setToken } = authSlice.actions
