import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { useDeleteTemplateMutation } from '../../../api/api'
import LoadingSpinner from '../../../components/atoms/loadingSpinner/LoadingSpinner'
import TextInput from '../../../components/organisms/textInput/TextInput'
import { TresponseError } from '../../../types/Tgeneral'

const notify = (message: string, autoClose = 3000) => toast(message, { autoClose })

const DeleteTemplate: React.FC = () => {
  const uniqueId = '91354'
  const { templateName } = useParams()
  const [nameInput, setNameInput] = useState('')
  const [formNotValid, setFormNotValid] = useState(false)

  const [deleteTemplate, { isLoading: isLoadingDelete, error, isSuccess, data: deleteResponse }] =
    useDeleteTemplateMutation()

  useEffect(() => {
    if (error) {
      const errorObject: TresponseError = { ...error } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }
    if (isSuccess) {
      notify('Deleted successfully!')
      setTimeout(() => {
        window.location.href = './templates'
      }, 2000)
    }
    isLoadingDelete && notify('Deleting...', 700)
    deleteResponse && notify(deleteResponse.message)
    error && notify(JSON.stringify(error), 4000)
  }, [error, isSuccess, isLoadingDelete, deleteResponse])

  isLoadingDelete && <LoadingSpinner />

  return (
    <div className='DeleteTemplate DeleteFeed pageContainer'>
      <div className='breadcrumbs'>
        <span>
          <Link to='/templates'>
            <p>templates</p>
          </Link>
        </span>
        <span> / </span>
        <span>delete : </span>
        <Link to={`/templates/htmlContent/${templateName}`}>
          <p>{templateName}</p>
        </Link>
      </div>
      <h1>
        Delete template <span>{templateName}</span>
      </h1>
      <form onSubmit={handleSubmit}>
        <div className='deleteAssurance compnentContainer'>
          <h2>If you are sure to delete this feed, copy feed name here:</h2>
          <div className='greyRoundContainer'>
            <TextInput
              label='Feed Name'
              onChange={(e) => setNameInput(e.currentTarget.value)}
              uniqueId={uniqueId}
              value={nameInput}
              onBlur={() => nameInput && nameInput !== templateName && setFormNotValid(true)}
              onFocus={() => setFormNotValid(false)}
              idAttr='FeedName'
            />
            <div className='compnentContainer formValidation'>
              {formNotValid && <p>Input name must be same as Feed name</p>}
            </div>
          </div>
        </div>
        <div className='buttons'>
          <button
            className='deleteButt buttonBase'
            type='submit'
            disabled={isLoadingDelete || nameInput !== templateName}
          >
            Delete
          </button>
        </div>
      </form>
      <ToastContainer limit={3} position='top-center' />
    </div>
  )
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (templateName) {
      await deleteTemplate(templateName)
    }
  }
}

export default DeleteTemplate
