import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { TpublisherConfig } from '../../../../../types/Tfeeds'
import { setDebugConfig } from '../../reduxStates'

const useAllHooks = (
  publisherConfig: TpublisherConfig,
  defaultPublisher: string,
  publisher: string,
) => {
  const dispatch = useDispatch()
  const uniqueId = '95111'

  const [configs, setConfigs] = useState<string>('')
  const [extrasDefault, setExtrasDefault] = useState<string>('')

  useEffect(() => {
    setExtrasDefault(JSON.stringify(publisherConfig))
  }, [publisherConfig])

  useEffect(() => {
    if (defaultPublisher === 'debug') {
      setConfigs(JSON.parse(JSON.stringify(publisherConfig, null, 2)))
    } else {
      setConfigs('{}')
    }
  }, [defaultPublisher, publisherConfig])

  useEffect(() => {
    if (Object.values(configs).length && publisher === 'debug') {
      dispatch(setDebugConfig(configs))
    }
  }, [dispatch, configs, publisher])

  return { uniqueId, configs, setConfigs, extrasDefault }
}

export default useAllHooks
