import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { useGetFeedStatisticsQuery } from '../../api/api'
import { ErrorField } from '../../components/atoms/copyErrorMsg/CopyErrorMsg'
import { MenuIcon } from '../../components/atoms/icons/menu/MenuIcon'
import { DividerLine } from '../../components/atoms/lines/DividerLine'
import LoadingSpinner from '../../components/atoms/loadingSpinner/LoadingSpinner'
import { DropDownContainer } from '../../components/organisms/dropdownContainer/DropDownContainer'
import { removeMiliSeconds } from '../../util/tools/tools'
import { statusColorizer } from '../feedsList/singleFeed/importRuns/components/SingleRun'
import { ChartPostings } from './ChartPostings'
import { ChartTotalPostings } from './ChartTotalPostings'
import './style.scss'

export const FeedPage: React.FC = () => {
  const { id } = useParams()
  const { data, isLoading } = useGetFeedStatisticsQuery(id)

  const [displayDropdown, setDisplayDropdown] = useState(false)
  const closeDropdown = () => {
    setDisplayDropdown(false)
  }

  return (
    <div className='FeedPage pageContainer'>
      <div className='breadcrumbs'>
        <span>
          <Link to='/feeds'>
            <p>feeds</p>
          </Link>
        </span>
        <span> / </span>
        <span>{data?.feed.name}</span>
      </div>
      <div className='titleAndOptions'>
        <h1>{data?.feed.name}</h1>
        <DropDownContainer
          closeDropdown={closeDropdown}
          setDisplayDropdown={setDisplayDropdown}
          displayDropdown={displayDropdown}
          clickText={<MenuIcon />}
        >
          <DropdownOptions id={data?.feed.id} />
        </DropDownContainer>
      </div>
      <Status isActive={data?.feed.isActive} />
      <DividerLine verticalMargin='xl' />

      <h2>Last 5 Import runs</h2>
      {isLoading && <LoadingSpinner />}
      {!data?.feed.importRuns[0]?.id ? (
        <h2>No import Run!</h2>
      ) : (
        <div className='table lastImportRun'>
          <ul className='tableHeader'>
            <li>ImportId</li>
            <li>Status</li>
            <li>Start time</li>
            <li>End time</li>
            <li>Error message</li>
          </ul>
          {data?.feed.importRuns.map((i: Iprops) => (
            <SingleImportRun
              id={i.id}
              endTime={i.endTime}
              startTime={i.startTime}
              errorMessage={i.errorMessage}
              status={i.status}
              key={i.id}
            />
          ))}
        </div>
      )}

      <DividerLine verticalMargin='xl' />
      <h2>Job postings</h2>
      <div className='table jobPostingStats'>
        <ul className='tableHeader'>
          <li style={{ color: 'black' }}>Total</li>
          <li style={{ color: 'brown' }}>Prepared</li>
          <li style={{ color: 'green' }}>Published</li>
          <li style={{ color: 'grey' }}>Unpublished</li>
        </ul>
        <ul className='row'>
          <li>{data?.feedStatistics?.allJobPostings}</li>
          <li>{data?.feedStatistics?.allPreparedPostings}</li>
          <li>{data?.feedStatistics?.allPublishedPostings}</li>
          <li>{data?.feedStatistics?.allUnpublishedPostings}</li>
        </ul>
        <div className='postingCharts'>
          <ChartTotalPostings feedStatistics={data?.feedStatistics} />
          <ChartPostings feedId={id} />
        </div>
      </div>
      <DividerLine verticalMargin='xl' />
    </div>
  )
}

type Istatus = { isActive: boolean }
const Status: React.FC<Istatus> = ({ isActive }) => {
  return (
    <h2 className='Status'>
      Status
      {isActive ? (
        <>
          <p style={{ color: 'green' }}>Active</p>
        </>
      ) : (
        <p style={{ color: 'gray' }}>Not active</p>
      )}
    </h2>
  )
}

interface Idropdown {
  id: number | undefined
}
const DropdownOptions: React.FC<Idropdown> = ({ id }) => {
  return (
    <ul>
      <Link to={`/editfeed/${id}`}>
        <li>Edit</li>
      </Link>
      {localStorage.userRole !== 'view' && (
        <Link to={`/deletefeed/${id}`}>
          <li className='delete'>Delete</li>
        </Link>
      )}
    </ul>
  )
}

interface Iprops {
  id: string
  status: string
  startTime: string
  endTime: string
  errorMessage: string
}
const SingleImportRun: React.FC<Iprops> = ({ id, status, startTime, endTime, errorMessage }) => {
  return (
    <div className='SingleRun row'>
      <ul>
        <Link to={`/feeds/jobpostingimports/${id}`}>
          <li>{id}</li>
        </Link>
        <Link to={`/feeds/jobpostingimports/${id}`}>
          <li style={{ color: statusColorizer(status) }}>{status}</li>
        </Link>
        <Link to={`/feeds/jobpostingimports/${id}`}>
          <li>{removeMiliSeconds(startTime)}</li>
        </Link>
        <Link to={`/feeds/jobpostingimports/${id}`}>
          <li>{removeMiliSeconds(endTime)}</li>
        </Link>
        <li>
          <ErrorField errorMessage={errorMessage} />
        </li>
      </ul>
    </div>
  )
}
