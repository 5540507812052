import { useDetectClickOutside } from 'react-detect-click-outside'

interface IpropsDropDownContainer {
  closeDropdown: () => void
  setDisplayDropdown: (e: boolean) => void
  displayDropdown: boolean
  children: JSX.Element
  clickText?: JSX.Element
}

export const DropDownContainer: React.FC<IpropsDropDownContainer> = ({
  closeDropdown,
  setDisplayDropdown,
  displayDropdown,
  children,
  clickText,
}) => {
  const ref = useDetectClickOutside({ onTriggered: closeDropdown })

  return (
    <div className='clickable' onClick={() => setDisplayDropdown(!displayDropdown)} ref={ref}>
      {clickText ? clickText : <>. . .</>}
      {displayDropdown ? <div className='AdvancedDropDown'>{children}</div> : null}
    </div>
  )
}
