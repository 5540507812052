import cron from 'cron-validate'

export function isJsonValid(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
export function isExtraValidJson(notify: (arg0: string) => void) {
  const element = Array.from(
    document.getElementsByClassName('defaultObjektInput') as HTMLCollectionOf<HTMLElement>,
  )
  if (!element.length) return true

  const textArea = element[0].style.borderColor

  if (textArea === 'red') {
    notify('Extras must be in right JSON format!')
    return false
  }
  return true
}

export function removeMiliSeconds(time: string) {
  return time
    ?.replace(/.\d+Z$/g, 'Z')
    .replace('Z', '')
    .replace('T', ' ')
    .slice(2)
}

export function colorizeImportStatus(status: string) {
  switch (status) {
    case 'planned':
      return 'grey'
    case 'processed':
      return 'green'
    case 'error':
      return 'red'
    case 'pending':
      return 'lightblue'
  }
}

export function colorizeImportRunStatus(status: string) {
  switch (status) {
    case 'error':
      return 'red'
    case 'running':
      return 'grey'
    case 'finished':
      return 'green'
    case 'planned':
      return 'grey'
  }
}

export function validateSchedule(schedule: string[]) {
  if (!schedule[0]) return false
  for (let index = 0; index < schedule.length; index++) {
    const element = schedule[index]
    if (!cron(element, { override: { useSeconds: false } }).isValid()) return false
  }
  return true
}
