import { DividerLine } from '../../../../components/atoms/lines/DividerLine'
import HtmlCodeView from '../../../../components/organisms/codeView/HtmlCodeView'
import { directJobs, jobFields, jobsContext, locations } from './SampleXmlJsonData'

const MappingHelperXml: React.FC = () => {
  return (
    <div className='MappingHelper pageContainer'>
      <h1>Xml path mapping guide</h1>
      <h3>Jobs list context</h3>
      <HtmlCodeView html={jobsContext} />
      <h3>In the example above:</h3>
      <h4>
        Jobs list context is the array which contains all of job postings and child job element,
        which is:
      </h4>
      <p>
        <span>Job list context: </span>
        <span>jobs/job</span>
      </p>
      <h4>
        But sometimes the response data gives directry the list of jobs
        {/* toask */}
      </h4>
      <HtmlCodeView html={directJobs} />
      <h4>In this case we just write the job tag</h4>
      <p>
        <span>Job list context: </span>
        <span>job</span>
      </p>
      <DividerLine />
      <h3>Root fields</h3>
      <HtmlCodeView html={jobFields} />
      <h4>Earlier we have defined the job path, so to map the Id, we can easily input:</h4>
      <p>
        <span>id: </span>
        <span>id</span>
      </p>
      <DividerLine />
      <p>
        <span>locations: </span>
        <span>locations</span>
      </p>
      <HtmlCodeView html={locations} />
      <h4>
        Its possible to have multiple locations, so first, in field &apos;locations&apos; we define
        the path which includes all locations, and then the child path for each item (ex:
        zip,city,...)
      </h4>
      <p>
        <span>locations: </span>
        <span>locations/location</span>
      </p>
      <p>
        <span>city: </span>
        <span>city</span>
      </p>
    </div>
  )
}

export default MappingHelperXml
