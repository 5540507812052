import React, { useState } from 'react'

import { DividerLine } from '../../atoms/lines/DividerLine'

interface Iprops {
  json: any
}

const JsonCodeView: React.FC<Iprops> = ({ json }) => {
  const [show, setShow] = useState(false)
  function handleOpen() {
    setShow(!show)
  }

  return (
    <div className='PreviewEdited'>
      <div className='container' onClick={handleOpen}>
        Json preview: {show ? 'hide' : 'show'}
      </div>
      <DividerLine></DividerLine>
      {show && <pre>{JSON.stringify(json, null, 2)}</pre>}
    </div>
  )
}

export default JsonCodeView
