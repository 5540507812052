import { Link } from 'react-router-dom'

import { TuserRole } from '../../types/Tgeneral'

export const SingleUser: React.FC<ISingleUser> = ({ id, username, role, createdAt }) => {
  return (
    <div className='SingleUser'>
      <Link to={`/users/${id}/${username}/${role}`}>
        <ul>
          <li>
            <span>Id</span>
            <span>{id}</span>
          </li>
          <li>
            <span>username</span>
            <span>{username}</span>
          </li>
          <li>
            <span>role</span>
            <span>{role}</span>
          </li>
          <li>
            <span>createdAt</span>
            <span>{createdAt}</span>
          </li>
        </ul>
      </Link>
    </div>
  )
}

export interface ISingleUser {
  id: string
  username: string
  role: TuserRole
  createdAt: string
}
