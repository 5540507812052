import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  TairtableConfig,
  TjassConfig,
  TreaderConfig,
  TschalttoolConfig,
} from '../../../types/Tfeeds'

const schalttoolConfig = {}
const jassConfig = {}
const otherConfig = {}
const debugConfig = {}
const readerConfig = {}
const airtableConfig = {}

const editFeedSlice = createSlice({
  name: 'editFeed',
  initialState: {
    schalttoolConfig,
    jassConfig,
    otherConfig,
    debugConfig,
    readerConfig,
    airtableConfig,
  },
  reducers: {
    setSchalttoolConfig(state, action: PayloadAction<TschalttoolConfig>) {
      state.schalttoolConfig = action.payload
    },
    setJassConfig(state, action: PayloadAction<TjassConfig>) {
      state.jassConfig = action.payload
    },
    setAirtableConfig(state, action: PayloadAction<TairtableConfig>) {
      state.airtableConfig = action.payload
    },
    setOtherConfig(state, action: PayloadAction<string>) {
      state.otherConfig = action.payload
    },
    setDebugConfig(state, action: PayloadAction<string>) {
      state.debugConfig = action.payload
    },
    setReaderConfig(state, action: PayloadAction<TreaderConfig>) {
      state.readerConfig = action.payload
    },
  },
})

export const {
  setSchalttoolConfig,
  setJassConfig,
  setOtherConfig,
  setDebugConfig,
  setReaderConfig,
  setAirtableConfig,
} = editFeedSlice.actions
export default editFeedSlice.reducer
