import { DividerLine } from '../../../../../components/atoms/lines/DividerLine'
import NumberInput from '../../../../../components/organisms/numberInput/NumberInput'
import SelectBox from '../../../../../components/organisms/selectBox/SelectBox'
import TextInput from '../../../../../components/organisms/textInput/TextInput'
import useAllHooks from './hooksAndStates'

const parsers = ['xml', 'json']

interface Iprops {
  publisher: string
}

export const Jass: React.FC<Iprops> = ({ publisher }) => {
  const {
    uniqueId,
    customerId,
    setCustomerId,
    productIds,
    setProductIds,
    parser,
    setParser,
    productIdsPath,
    setProductIdsPath,
    customerIdPath,
    setCustomerIdPath,
    apiKey,
    setApiKey,
    username,
    setUsername,
    password,
    setPassword,
  } = useAllHooks(publisher)
  return (
    <div className='Jass publisherSelected'>
      <div className='inputContainer'>
        <NumberInput
          label='Customer ID'
          onChange={(e) => setCustomerId(e.currentTarget.valueAsNumber)}
          uniqueId={uniqueId}
          value={customerId}
          idAttr='customerId'
        />
        <div className='NumberInput'>
          <label htmlFor={`setProductIds-${uniqueId}`}>Product IDs</label>
          <input
            id={`setProductIds-${uniqueId}`}
            value={productIds[0]}
            onChange={(e) => setProductIds([e.target.valueAsNumber])}
            type='number'
            required
            data-test-id={`setProductIds-${uniqueId}`}
          />
        </div>
        <SelectBox
          label='Parser'
          onChange={setParser}
          selectList={parsers}
          uniqueId={uniqueId}
          defaultValue={parser}
        />
        <TextInput
          label='Product Ids path'
          onChange={(e) => setProductIdsPath(e.currentTarget.value)}
          uniqueId={uniqueId}
          value={productIdsPath}
          required={false}
        />
        <TextInput
          label='Customer Id path'
          onChange={(e) => setCustomerIdPath(e.currentTarget.value)}
          uniqueId={uniqueId}
          value={customerIdPath}
          required={false}
        />
        <div className='credentials'>
          <DividerLine />
          <h4>Credentials</h4>
          <TextInput
            label='API key'
            onChange={(e) => setApiKey(e.currentTarget.value)}
            uniqueId={uniqueId}
            value={apiKey}
            required={false}
            type='password'
          />
          <TextInput
            label='Username'
            onChange={(e) => setUsername(e.currentTarget.value)}
            uniqueId={uniqueId}
            value={username}
            required={false}
          />
          <TextInput
            label='Password'
            onChange={(e) => setPassword(e.currentTarget.value)}
            uniqueId={uniqueId}
            value={password}
            required={false}
            type='password'
          />
        </div>
      </div>
    </div>
  )
}
